import {
  Facet,
  FacetReducerActions,
  AddAttributeValueFacetFilterAction,
  RemoveAttributeValueFacetFilterAction,
  ADD_ATTRIBUTE_VALUE_FACET_FILTER,
  REMOVE_ATTRIBUTE_VALUE_FACET_FILTER,
} from "./FacetTypes";

export interface FacetState {
  facets: Facet[];
  facetsSFCC: Facet[];
  facetsToAdd: Facet[];
  filterValues: string[];
  isFacetsFetching: boolean;
  isFacetsFetched: boolean;
}

const initialState: FacetState = {
  facets: [],
  facetsSFCC: [],
  facetsToAdd: [],
  filterValues: [],
  isFacetsFetching: false,
  isFacetsFetched: false,
};

export const FacetReducer = (
  state = initialState,
  action: FacetReducerActions,
) => {
  switch (action.type) {
    case ADD_ATTRIBUTE_VALUE_FACET_FILTER:
      const addFilterAction = action as AddAttributeValueFacetFilterAction;
      const toAddValues = state.filterValues.slice();
      addFilterAction.payload.attributeValueIds.forEach((valueId) => {
        if (!toAddValues.find((addValue) => addValue === valueId)) {
          toAddValues.push(valueId);
        }
      });
      return {
        ...state,
        filterValues: toAddValues,
      };
    case REMOVE_ATTRIBUTE_VALUE_FACET_FILTER:
      const removeFilterAction =
        action as RemoveAttributeValueFacetFilterAction;
      const toRemoveValues = state.filterValues.slice();
      return {
        ...state,
        filterValues: toRemoveValues.filter(
          (value) =>
            !removeFilterAction.payload.attributeValueIds.includes(value),
        ),
      };
    default:
      return state;
  }
};
