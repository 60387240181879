import { takeEvery, put } from "redux-saga/effects";
import {
  DeleteFacetFromCategoryAction,
  REMOVE_FACET_FROM_CATEGORY,
  DELETE_FACET_FROM_CATEGORY,
  ADD_FACET_TO_CATEGORY,
  AddFacetToCategoryAction,
  INSERT_FACET_TO_CATEGORY,
  InsertFacetsToCategoryObject,
  CHANGE_ATTRIBUTE_VALUE_SEQUENCES,
  UpdateAttributeValueSequencesAction,
  UPDATE_ATTRIBUTE_VALUE_SEQUENCES,
  UpdateFacetSequencesAction,
  UPDATE_FACET_SEQUENCES,
  CHANGE_FACET_SEQUENCES,
  UPDATE_INHERITED_FACET,
  CHANGE_INHERITED_FACET,
  UpdateInheritedFacetAction,
  CHANGE_SFCC_FACET_SEQUENCES,
  UpdateSFCCFacetSequencesAction,
  UPDATE_SFCC_FACET_SEQUENCES,
  CHANGE_FACET_BUCKET_SEQUENCES,
  UpdateFacetBucketSequencesAction,
  UPDATE_FACET_BUCKET_SEQUENCES,
  UpdateFacetBucketDescriptionAction,
  UPDATE_FACET_BUCKET_DESCRIPTION,
  CHANGE_FACET_BUCKET_DESCRIPTION,
  ADD_FACET_BUCKET_VALUE,
  DELETE_FACET_BUCKET_VALUE,
  AddFacetBucketValueAction,
  INSERT_FACET_BUCKET_VALUE,
  DeleteFacetBucketValueAction,
  REMOVE_FACET_BUCKET_VALUE,
  CHANGE_FACET_BUCKET,
  UpdateFacetBucketAction,
  UPDATE_FACET_BUCKET,
  AddFacetBucketAction,
  INSERT_FACET_BUCKET,
  ADD_FACET_BUCKET,
} from "./FacetTypes";
import {
  deleteFacetFromCategoryQuery,
  addFacetToCategoryQuery,
  updateFacetSequencesQueryGenerator,
  addBlockInheritanceQuery,
  removeBlockInheritanceQuery,
  updateSFCCFacetSequencesQueryGenerator,
  updateFacetBucketSequencesQueryGenerator,
  updateFacetBucketDescriptionQuery,
  insertFacetBucketValueQuery,
  deleteFacetBucketValueQuery,
  updateFacetBucketQuery,
  addFacetBucketQuery,
} from "./FacetSchema";
import {
  SyncLogInserts,
  BucketValueInserts,
} from "../../services/GraphQLTypes";
import { callGraphQLApi } from "../../utils/SagaUtils";
import GraphQLService from "../../services/GraphQL";
import { getAttributeValueSquenceGraphQLQuery } from "../product/ProductOperations";

export function* watchDeleteFacetsFromCategory() {
  yield takeEvery(DELETE_FACET_FROM_CATEGORY, deleteFacetFromCategory);
}

export function* watchAddFacetsToCategory() {
  yield takeEvery(ADD_FACET_TO_CATEGORY, addFacetToCategory);
}

export function* watchUpdateAttributeValueSequences() {
  yield takeEvery(
    CHANGE_ATTRIBUTE_VALUE_SEQUENCES,
    updateAttributeValueSeuqences,
  );
}

export function* watchUpdateFacetSequences() {
  yield takeEvery(CHANGE_FACET_SEQUENCES, updateFacetSeuqences);
}

export function* watchUpdateSFCCFacetSequences() {
  yield takeEvery(CHANGE_SFCC_FACET_SEQUENCES, updateSFCCFacetSeuqences);
}

export function* watchUpdateInheritedFacet() {
  yield takeEvery(CHANGE_INHERITED_FACET, updateInheritedFacet);
}

export function* watchUpdateFacetBucketSequences() {
  yield takeEvery(CHANGE_FACET_BUCKET_SEQUENCES, updateFacetBucketSeuqences);
}

export function* watchUpdateFacetBucketDescription() {
  yield takeEvery(
    CHANGE_FACET_BUCKET_DESCRIPTION,
    updateFacetBucketDescription,
  );
}

export function* watchAddFacetBucketValues() {
  yield takeEvery(ADD_FACET_BUCKET_VALUE, addFacetBucketValues);
}

export function* watchDeleteFacetBucketValues() {
  yield takeEvery(DELETE_FACET_BUCKET_VALUE, deleteFacetBucketValues);
}

export function* watchUpdateFacetBucket() {
  yield takeEvery(CHANGE_FACET_BUCKET, updateFacetBucket);
}

export function* watchAddFacetBucket() {
  yield takeEvery(ADD_FACET_BUCKET, addFacetBucket);
}

function* deleteFacetFromCategory(action: DeleteFacetFromCategoryAction) {
  const actionType = REMOVE_FACET_FROM_CATEGORY;
  try {
    const facetId = action.payload.facetId;
    const storeId = action.payload.storeId;
    const categoryId = action.payload.categoryId;
    const syncLogInserts: SyncLogInserts[] = [
      {
        tableName: "CategoryToFacet",
        operationType: "DELETED",
        keyName1: "facetId",
        oldKeyValue1: facetId,
        keyName2: "storeId",
        oldKeyValue2: storeId,
        keyName3: "categoryId",
        oldKeyValue3: categoryId,
      },
    ];
    const query = {
      query: deleteFacetFromCategoryQuery,
      variables: {
        facetId,
        categoryId,
        storeId,
        syncLogInserts,
      },
    };

    yield callGraphQLApi(actionType, GraphQLService.query, query);
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

function* addFacetToCategory(action: AddFacetToCategoryAction) {
  const actionType = INSERT_FACET_TO_CATEGORY;
  try {
    const facetIds = action.payload.facetIds;
    const storeId = action.payload.storeId;
    const categoryId = action.payload.categoryId;
    const categoryToFacetInserts: InsertFacetsToCategoryObject[] = [];
    const syncLogInserts: SyncLogInserts[] = [];
    facetIds.forEach((facetId) => {
      categoryToFacetInserts.push({
        categoryId,
        facetId,
        storeId,
        isEnabled: true,
      });
      syncLogInserts.push({
        tableName: "CategoryToFacet",
        operationType: "INSERTED",
        keyName1: "facetId",
        newKeyValue1: facetId,
        keyName2: "storeId",
        newKeyValue2: storeId,
        keyName3: "categoryId",
        newKeyValue3: categoryId,
      });
    });
    const query = {
      query: addFacetToCategoryQuery,
      variables: {
        categoryToFacetInserts,
        syncLogInserts,
      },
    };

    yield callGraphQLApi(actionType, GraphQLService.query, query);
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

function* updateAttributeValueSeuqences(
  action: UpdateAttributeValueSequencesAction,
) {
  const actionType = UPDATE_ATTRIBUTE_VALUE_SEQUENCES;
  try {
    const valueSequences = action.payload.valueSequences;
    const localeCode = action.payload.localeCode;
    const codes = localeCode ? [localeCode] : [];
    const syncLogInserts: SyncLogInserts[] = [];
    valueSequences.forEach((valueSeq) => {
      syncLogInserts.push({
        tableName: "AttributeValue",
        operationType: "UPDATED",
        keyName1: "attributeValueId",
        oldKeyValue1: valueSeq.attributeValueId,
        newKeyValue1: valueSeq.attributeValueId,
      });
    });
    const query = getAttributeValueSquenceGraphQLQuery(valueSequences, codes);

    yield callGraphQLApi(actionType, GraphQLService.query, query);
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

function* updateFacetSeuqences(action: UpdateFacetSequencesAction) {
  const actionType = UPDATE_FACET_SEQUENCES;
  try {
    const facetSequences = action.payload.facetSequences;
    const storeId = action.payload.storeId;
    const categoryId = action.payload.categoryId;
    const syncLogInserts: SyncLogInserts[] = [];
    facetSequences.forEach((facetSeq) => {
      syncLogInserts.push({
        tableName: "CategoryToFacet",
        operationType: "UPDATED",
        keyName1: "facetId",
        oldKeyValue1: facetSeq.facetId,
        newKeyValue1: facetSeq.facetId,
        keyName2: "storeId",
        oldKeyValue2: storeId,
        newKeyValue2: storeId,
        keyName3: "categoryId",
        oldKeyValue3: categoryId,
        newKeyValue3: categoryId,
      });
    });
    const generated = updateFacetSequencesQueryGenerator(facetSequences);

    const query = {
      query: generated,
      variables: {
        storeId,
        categoryId,
        syncLogInserts,
      },
    };

    yield callGraphQLApi(actionType, GraphQLService.query, query);
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

function* updateSFCCFacetSeuqences(action: UpdateSFCCFacetSequencesAction) {
  const actionType = UPDATE_SFCC_FACET_SEQUENCES;
  try {
    const facetSequences = action.payload.facetSequences;
    const syncLogInserts: SyncLogInserts[] = [];
    facetSequences.forEach((facetSeq) => {
      syncLogInserts.push({
        tableName: "Facet",
        operationType: "UPDATED",
        keyName1: "facetId",
        oldKeyValue1: facetSeq.facetId,
        newKeyValue1: facetSeq.facetId,
      });
    });
    const generated = updateSFCCFacetSequencesQueryGenerator(facetSequences);

    const query = {
      query: generated,
      variables: {
        syncLogInserts,
      },
    };

    yield callGraphQLApi(actionType, GraphQLService.query, query);
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

function* updateInheritedFacet(action: UpdateInheritedFacetAction) {
  const actionType = UPDATE_INHERITED_FACET;
  try {
    const categoryId = action.payload.categoryId;
    const facetId = action.payload.facetId;
    const isEnabled = action.payload.isEnabled;
    const syncLogInserts: SyncLogInserts[] = [
      {
        tableName: "Facet",
        operationType: isEnabled ? "INSERTED" : "DELETED",
        keyName1: "facetId",
        oldKeyValue1: facetId,
      },
    ];
    const insertVariables = {
      facetId,
      categoryId,
      attributeId: action.payload.attributeId,
      type: action.payload.type,
      syncLogInserts,
    };
    const deleteVariables = {
      facetId,
      syncLogInserts,
    };
    const query = {
      query: isEnabled ? addBlockInheritanceQuery : removeBlockInheritanceQuery,
      variables: isEnabled ? insertVariables : deleteVariables,
    };

    yield callGraphQLApi(actionType, GraphQLService.query, query);
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

function* updateFacetBucketSeuqences(action: UpdateFacetBucketSequencesAction) {
  const actionType = UPDATE_FACET_BUCKET_SEQUENCES;
  try {
    const facetBucketSequences = action.payload.facetBucketSequences;
    const facetId = action.payload.facetId;
    const syncLogInserts: SyncLogInserts[] = [];
    facetBucketSequences.forEach((facetBucketSeq) => {
      syncLogInserts.push({
        tableName: "FacetBucket",
        operationType: "UPDATED",
        keyName1: "facetId",
        oldKeyValue1: facetId,
        newKeyValue1: facetId,
        keyName2: "facetBucketId",
        oldKeyValue2: facetBucketSeq.facetBucketId,
        newKeyValue2: facetBucketSeq.facetBucketId,
      });
    });
    const generated =
      updateFacetBucketSequencesQueryGenerator(facetBucketSequences);

    const query = {
      query: generated,
      variables: {
        facetId,
        syncLogInserts,
      },
    };

    yield callGraphQLApi(actionType, GraphQLService.query, query);
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

function* updateFacetBucketDescription(
  action: UpdateFacetBucketDescriptionAction,
) {
  const actionType = UPDATE_FACET_BUCKET_DESCRIPTION;
  try {
    const localeCode = action.payload.localeCode;
    const facetBucketId = action.payload.facetBucketId;
    const displayValue = action.payload.displayValue;
    const syncLogInserts: SyncLogInserts[] = [];
    syncLogInserts.push({
      tableName: "FacetBucketDescription",
      operationType: "UPDATED",
      keyName1: "facetBucketId",
      oldKeyValue1: facetBucketId,
      newKeyValue1: facetBucketId,
      keyName2: "localeCode",
      oldKeyValue2: localeCode,
      newKeyValue2: localeCode,
    });

    const query = {
      query: updateFacetBucketDescriptionQuery,
      variables: {
        facetBucketId,
        localeCode,
        displayValue,
        syncLogInserts,
      },
    };

    yield callGraphQLApi(actionType, GraphQLService.query, query);
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

function* addFacetBucketValues(action: AddFacetBucketValueAction) {
  const actionType = INSERT_FACET_BUCKET_VALUE;
  try {
    const facetBucketId = action.payload.facetBucketId;
    const bucketValues = action.payload.bucketValues;
    const attributeId = action.payload.attributeId;
    const syncLogInserts: SyncLogInserts[] = [];
    const bucketValueInserts: BucketValueInserts[] = [];
    bucketValues.forEach((bucketValue) => {
      syncLogInserts.push({
        tableName: "FacetBucketValue",
        operationType: "INSERTED",
        keyName1: "facetBucketId",
        newKeyValue1: facetBucketId,
        keyName2: "bucketValue",
        newKeyValue2: attributeId + ":" + bucketValue,
      });
      bucketValueInserts.push({
        facetBucketId: facetBucketId,
        bucketValue: attributeId + ":" + bucketValue,
      });
    });

    const query = {
      query: insertFacetBucketValueQuery,
      variables: {
        bucketValueInserts,
        syncLogInserts,
      },
    };

    yield callGraphQLApi(actionType, GraphQLService.query, query);
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

function* deleteFacetBucketValues(action: DeleteFacetBucketValueAction) {
  const actionType = REMOVE_FACET_BUCKET_VALUE;
  try {
    const facetBucketId = action.payload.facetBucketId;
    const bucketValues = action.payload.bucketValues;
    const attributeId = action.payload.attributeId;
    const syncLogInserts: SyncLogInserts[] = [];
    const deleteValues: string[] = [];
    bucketValues.forEach((bucketValue) => {
      syncLogInserts.push({
        tableName: "FacetBucketValue",
        operationType: "DELETED",
        keyName1: "facetBucketId",
        oldKeyValue1: facetBucketId,
        keyName2: "bucketValue",
        oldKeyValue2: attributeId + ":" + bucketValue,
      });
      deleteValues.push(attributeId + ":" + bucketValue);
    });

    const query = {
      query: deleteFacetBucketValueQuery,
      variables: {
        facetBucketId,
        bucketValues: deleteValues,
        syncLogInserts,
      },
    };

    yield callGraphQLApi(actionType, GraphQLService.query, query);
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

function* updateFacetBucket(action: UpdateFacetBucketAction) {
  const actionType = UPDATE_FACET_BUCKET;
  try {
    const facetBucketId = action.payload.facetBucketId;
    const threshold = action.payload.threshold;
    const syncLogInserts: SyncLogInserts[] = [
      {
        tableName: "FacetBucket",
        operationType: "UPDATED",
        oldKeyValue1: facetBucketId,
        newKeyValue1: facetBucketId,
      },
    ];

    const query = {
      query: updateFacetBucketQuery,
      variables: {
        facetBucketId,
        threshold,
        syncLogInserts,
      },
    };

    yield callGraphQLApi(actionType, GraphQLService.query, query);
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

function* addFacetBucket(action: AddFacetBucketAction) {
  const actionType = INSERT_FACET_BUCKET;
  try {
    const facetBucket = [action.payload.facetBucket];
    const syncLogInserts: SyncLogInserts[] = [];

    syncLogInserts.push({
      tableName: "FacetBucket",
      operationType: "INSERTED",
      keyName1: "facetBucketId",
      newKeyValue1: action.payload.facetBucketId,
    });

    syncLogInserts.push({
      tableName: "FacetBucketDescription",
      operationType: "INSERTED",
      keyName1: "facetBucketId",
      newKeyValue1: action.payload.facetBucketId,
      keyName2: "localeCode",
      newKeyValue2: action.payload.localeCode,
    });

    const query = {
      query: addFacetBucketQuery,
      variables: {
        facetBucket,
        syncLogInserts,
      },
    };

    yield callGraphQLApi(actionType, GraphQLService.query, query);
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}
