import {
  BusinessRulesListDetails,
  ProductPublishedData,
} from "../store/product-list/ProductListTypes";
import {
  Product,
  ProductAttributeValue,
  ProductSwatchType,
  VariantAttribute,
  ChildProduct,
  CategoryType,
} from "../store/product/ProductTypes";
import { ColorManagementProductData } from "../store/color-management/ColorManagementTypes";
import {
  LeadingImageVariationAttributesData,
  PreSelectedColorType,
} from "../store/leading-image/LeadingImageTypes";

export const isProductPublished = (product: ProductPublishedData): boolean => {
  const productIsPublished = product.isPublished;
  const localeIsPublished =
    product.descriptions && product.descriptions.length > 0
      ? product.descriptions[0].isPublished
      : true;

  return productIsPublished && localeIsPublished;
};
export const isProductPublishedSFCC = (
  product?: ProductPublishedData,
): boolean => {
  const productIsPublished = product?.isPublished ?? false;

  return productIsPublished;
};

export const isProductPublishedSFCCStore = (
  product: ProductPublishedData,
  storeId: string,
): boolean | null => {
  if ((product.overrides?.length ?? 0) > 0) {
    return (
      product.overrides?.filter((item) => item.storeId === storeId)[0]
        ?.isPublished ?? null
    );
  }
  return null;
};

export const getProductName = (
  product: Product,
  localeCode: string,
  defaultLocaleCode: string,
): string => {
  const description =
    product.descriptions?.find((desc) => desc.localeCode === localeCode) ||
    product.descriptions?.find((desc) => desc.localeCode === defaultLocaleCode);
  return description
    ? description.name
    : product.code
      ? product.code
      : product.productId;
};

export const getProductImageSFCC = (
  product: Product | ChildProduct,
  localeCode: string,
  defaultLocaleCode: string,
): string => {
  const description =
    product.descriptions?.find((desc) => desc.localeCode === localeCode) ||
    product.descriptions?.find((desc) => desc.localeCode === defaultLocaleCode);
  return description ? description.thumbnail : "";
};

export const getAttributeDisplayNameSFCC = (
  productAttributeValue: ProductAttributeValue,
  localeCode: string,
  defaultLocaleCode: string,
): string => {
  const desc =
    productAttributeValue.attributeValue.attribute.descriptions.find(
      (description) => description.localeCode === localeCode,
    ) ||
    productAttributeValue.attributeValue.attribute.descriptions.find(
      (description) => description.localeCode === defaultLocaleCode,
    );
  const attributeDisplayName = desc
    ? desc.displayName
    : productAttributeValue.attributeValue.attribute.code
      ? productAttributeValue.attributeValue.attribute.code
      : productAttributeValue.attributeValue.attribute.attributeId;

  return attributeDisplayName;
};

export const getAttributeValueDisplayNameSFCC = (
  productAttributeValue: ProductAttributeValue,
  localeCode: string,
  defaultLocaleCode: string,
): string => {
  const desc =
    productAttributeValue.attributeValue.descriptions.find(
      (description) => description.localeCode === localeCode,
    ) ||
    productAttributeValue.attributeValue.descriptions.find(
      (description) => description.localeCode === defaultLocaleCode,
    );
  const attributeValueDisplayName = desc
    ? desc.displayValue
    : productAttributeValue.attributeValue.code
      ? productAttributeValue.attributeValue.code
      : productAttributeValue.attributeValueId;

  return attributeValueDisplayName;
};

export const getVariantAttributeDisplayName = (
  attribute: VariantAttribute,
  localeCode: string,
  defaultLocaleCode: string,
): string => {
  const desc =
    attribute.descriptions.find(
      (description) => description.localeCode === localeCode,
    ) ||
    attribute.descriptions.find(
      (description) => description.localeCode === defaultLocaleCode,
    );
  const attributeDisplayName = desc
    ? desc.displayValue
    : attribute.code
      ? attribute.code
      : attribute.attributeId;
  return attributeDisplayName;
};

export const getVariantAttributeValueDisplayName = (
  attributeValue: VariantAttribute,
  localeCode: string,
  defaultLocaleCode: string,
): string => {
  const desc =
    attributeValue.descriptions.find(
      (description) => description.localeCode === localeCode,
    ) ||
    attributeValue.descriptions.find(
      (description) => description.localeCode === defaultLocaleCode,
    );
  const attributeValueDisplayName = desc
    ? desc.displayValue
    : attributeValue.code
      ? attributeValue.code
      : attributeValue.attributeId;
  return attributeValueDisplayName;
};

export const getAttributeValueSequenceSFCC = (
  productAttributeValue: ProductAttributeValue,
  localeCode: string,
  defaultLocaleCode: string,
): number => {
  const desc =
    productAttributeValue.attributeValue.descriptions.find(
      (description) => description.localeCode === localeCode,
    ) ||
    productAttributeValue.attributeValue.descriptions.find(
      (description) => description.localeCode === defaultLocaleCode,
    );
  return desc ? desc.sequence : 0;
};

export const getProductCategoryNameSFCC = (
  categoryObj: CategoryType,
  localeCode: string,
  defaultLocaleCode: string,
): string => {
  const desc =
    categoryObj.translations.find(
      (translation) => translation.localCode === localeCode,
    ) ||
    categoryObj.translations.find(
      (translation) => translation.localCode === defaultLocaleCode,
    );
  const productCategoryName = desc ? desc.name : categoryObj.name;
  return productCategoryName;
};

export const getProductSwatchColorSFCC = (
  swatch: ProductSwatchType,
  localeCode: string,
  defaultLocaleCode: string,
): string => {
  const desc =
    swatch.colorDescriptions.find(
      (description) => description.localeCode === localeCode,
    ) ||
    swatch.colorDescriptions.find(
      (description) => description.localeCode === defaultLocaleCode,
    );
  const swatchColor = desc ? desc.displayValue : "";
  return swatchColor;
};

export const getProductSwatchImageSFCC = (
  swatch: ProductSwatchType,
  localeCode: string,
  defaultLocaleCode: string,
): string | undefined => {
  const desc =
    swatch.colorDescriptions.find(
      (description) => description.localeCode === localeCode,
    ) ||
    swatch.colorDescriptions.find(
      (description) => description.localeCode === defaultLocaleCode,
    );
  const thumbnail = desc?.thumbnail;
  return thumbnail;
};

export const getProductSwatchChildProductImageSFCC = (
  swatch: ProductSwatchType,
  localeCode: string,
  defaultLocaleCode: string,
): string | undefined => {
  const desc =
    swatch.childProductDescriptions.find(
      (description) => description.localeCode === localeCode,
    ) ||
    swatch.childProductDescriptions.find(
      (description) => description.localeCode === defaultLocaleCode,
    );
  const thumbnail = desc?.thumbnail;
  return thumbnail;
};

export const getProductSwatchLocaleSequenceSFCC = (
  swatch: ProductSwatchType,
  localeCode: string,
  defaultLocaleCode: string,
): number => {
  const desc =
    swatch.colorDescriptions.find(
      (description) => description.localeCode === localeCode,
    ) ||
    swatch.colorDescriptions.find(
      (description) => description.localeCode === defaultLocaleCode,
    );
  const sequence = desc?.sequence;
  return sequence || 0;
};

export const calculateStartEndDate = (
  sortingRule: BusinessRulesListDetails,
): { startDate: string; endDate: string } => {
  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1;
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();

  const formatDate = (date: number) => (date < 10 ? "0" + date : date);

  let startDate;
  let endDate = `${year}${formatDate(month)}${formatDate(day)}`;

  switch (sortingRule.ruleType) {
    case "MCS":
      const wsCriteria = sortingRule.criteria.find((c) => c.ruleType === "WS");
      startDate = wsCriteria ? wsCriteria.dateRange : "last_14_days";
      break;
    default:
      startDate = sortingRule.dateRange || "last_14_days";
      break;
  }

  const calculateDate = (days: number) => {
    const newDateObj = new Date(dateObj);
    newDateObj.setDate(dateObj.getDate() - days);
    const newMonth = newDateObj.getUTCMonth() + 1;
    const newDay = newDateObj.getUTCDate();
    const newYear = newDateObj.getUTCFullYear();
    return `${newYear}${formatDate(newMonth)}${formatDate(newDay)}`;
  };

  switch (startDate) {
    case "last_7_days":
      startDate = calculateDate(7);
      break;
    case "last_14_days":
      startDate = calculateDate(14);
      break;
    case "last_30_days":
      startDate = calculateDate(30);
      break;
    case "last_90_days":
      startDate = calculateDate(90);
      break;
    case "last_180_days":
      startDate = calculateDate(180);
      break;
    default:
      startDate = calculateDate(14);
      break;
  }

  return { startDate, endDate };
};

export const getProductLeadingColorImageSFCC = (
  productData: Product | ColorManagementProductData,
  currentCategoryId: string,
) => {
  let index;
  if (productData.categorySpecific && productData?.colors) {
    const productCategorySpecificData = productData?.categorySpecific.filter(
      (categoryObj) => {
        return (
          Object.values(categoryObj).includes(currentCategoryId) &&
          categoryObj.leadingColorId
        );
      },
    );
    index = productData?.colors?.findIndex(
      (color) =>
        productCategorySpecificData.length > 0 &&
        color.colorId === productCategorySpecificData[0].leadingColorId &&
        color.isPublished,
    );
    return index >= 0 ? productData?.colors[index].thumbnail : "";
  }
};

export const getProductLeadingImageOnInitialLoad = (
  productData: Product | null,
  currentCategoryId: string,
) => {
  let leadingImage: string = "";
  if (
    productData &&
    productData?.categorySpecific?.length > 0 &&
    productData?.colors
  ) {
    productData?.categorySpecific?.forEach((categoryObj) => {
      if (
        Object.values(categoryObj).includes(currentCategoryId) &&
        categoryObj.leadingImageId
      ) {
        if (productData && productData?.imageGroups) {
          for (const imageGroup of productData.imageGroups) {
            if (!leadingImage) {
              const index = imageGroup.images?.findIndex(
                (image) => image.imageId === categoryObj?.leadingImageId,
              );
              if (
                index >= 0 &&
                productData?.colors &&
                imageGroup?.variationAttributes?.some(
                  (attr) =>
                    attr.attributeId ===
                      productData?.colorVariationAttributeId &&
                    attr.values?.some(
                      (attrValue) =>
                        productData?.colors?.find(
                          (color) => color.colorId === attrValue.value,
                        )?.isPublished,
                    ),
                )
              ) {
                leadingImage = imageGroup?.images?.[index]?.thumbnail;
                return leadingImage;
              }
            }
          }
        }
      }
    });
  }
  return leadingImage;
};

export const getProductColorSFCC = (
  baseProductUpdatedLeadingColor: string,
  productData: Product | null,
  currentCategoryId: string,
  variationAttributes: LeadingImageVariationAttributesData[],
) => {
  let preSelectedColor: PreSelectedColorType = {};
  const colorAttrId = productData?.colorVariationAttributeId;
  if (colorAttrId === "color") {
    if (
      baseProductUpdatedLeadingColor &&
      baseProductUpdatedLeadingColor !== "" &&
      productData?.colors.find(
        (color) => color.name === baseProductUpdatedLeadingColor,
      )?.isPublished
    ) {
      preSelectedColor = {
        [colorAttrId]: baseProductUpdatedLeadingColor,
      };
      return preSelectedColor;
    } else {
      const productCategorySpecificData = productData?.categorySpecific?.filter(
        (categoryObj) => {
          return (
            Object.values(categoryObj).includes(currentCategoryId) &&
            categoryObj.leadingColorId
          );
        },
      );
      const leadingColor =
        productCategorySpecificData && productCategorySpecificData?.length > 0
          ? productCategorySpecificData[0].leadingColorId
          : "";
      const colorData = productData?.colors.find(
        (color) => color.colorId === leadingColor,
      )?.isPublished;
      if (leadingColor && leadingColor !== "" && colorData) {
        preSelectedColor = {
          [colorAttrId]: leadingColor,
        };
        return preSelectedColor;
      } else {
        if (variationAttributes.length > 0) {
          for (const attr of variationAttributes) {
            if (attr.attributeId === "color") {
              const attrValues = attr.values
                ?.filter(
                  (value) =>
                    productData?.colors.find(
                      (color) => color.name === value.name,
                    )?.isPublished,
                )
                .find((value) => value.isAssigned === true);
              if (attrValues) {
                preSelectedColor = {
                  [colorAttrId]: attrValues.value,
                };
                return preSelectedColor;
              }
            }
          }
        }
      }
    }
  }
};

export const isAbsolutePath = (imagePath?: string | null) => {
  try {
    const path = imagePath ?? "";
    new URL(path);
    return true; // It's a valid URL (which is considered absolute)
  } catch (error) {
    return false; // It's not a valid URL (could be a relative path)
  }
};

export const isListPriceGreaterThanPrice = (
  price: string | null,
  priceRange: number[] | null,
) => {
  let isGreaterListPrice = false;
  if (price && priceRange) {
    //Check if high Price exists
    if (priceRange[1]) {
      //Verifying List Price greater than high Price
      if (Number(price) > priceRange[1]) isGreaterListPrice = true;
    } else if (priceRange[0]) {
      //If high price does not exists checking with low price
      if (Number(price) > priceRange[0]) isGreaterListPrice = true;
    }
  }

  return isGreaterListPrice;
};
