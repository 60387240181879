import { AttributeData } from "../category/CategoryTypes";
import { AttrValueSequence } from "../product/ProductTypes";

export type FacetReducerActions =
  | AddAttributeValueFacetFilterAction
  | RemoveAttributeValueFacetFilterAction;

export interface CategoryToFacetData {
  CategoryToFacet: FacetData[];
}

export interface FacetData {
  facet: Facet;
  sequence: number;
}

export interface Facet {
  attributeId: string;
  categoryId: string;
  descriptions: FacetDescription[];
  facetId: string;
  isEnabled: boolean;
  isMultiSelect: boolean;
  isZeroDisplay: boolean;
  limit: number;
  sequence: number;
  sortMode: string;
  storeId: string;
  type: string;
  attribute: AttributeData | null;
  bucketMode: string;
  unbucketedValuesMode: string;
  buckets: FacetBucket[];
}

export interface FacetDescription {
  displayName: string;
  localeCode: string;
}

export interface FacetBucket {
  facetBucketId: string;
  isDefault: boolean;
  sequence: number;
  threshold: number;
  values: FacetBucketValue[];
  descriptions: FacetBucketDescription[];
}

export interface FacetBucketValue {
  bucketValue: string;
}

export interface FacetBucketDescription {
  displayValue: string;
  localeCode: string;
}

/**
 * Delete facet from category types
 */
export const DELETE_FACET_FROM_CATEGORY = "DELETE_FACET_FROM_CATEGORY";
export const REMOVE_FACET_FROM_CATEGORY = {
  REQUEST: "REMOVE_FACET_FROM_CATEGORY_REQUEST",
  SUCCESS: "REMOVE_FACET_FROM_CATEGORY_SUCCESS",
  FAILURE: "REMOVE_FACET_FROM_CATEGORY_FAILURE",
};

export interface DeleteFacetFromCategoryPayload {
  facetId: string;
  categoryId: string;
  storeId: string;
}

export interface DeleteFacetFromCategoryAction {
  payload: DeleteFacetFromCategoryPayload;
  type: typeof DELETE_FACET_FROM_CATEGORY;
}

/**
 * Add facet to category types
 */
export const ADD_FACET_TO_CATEGORY = "ADD_FACET_TO_CATEGORY";
export const INSERT_FACET_TO_CATEGORY = {
  REQUEST: "INSERT_FACET_TO_CATEGORY_REQUEST",
  SUCCESS: "INSERT_FACET_TO_CATEGORY_SUCCESS",
  FAILURE: "INSERT_FACET_TO_CATEGORY_FAILURE",
};

export interface AddFacetToCategoryPayload {
  facetIds: string[];
  categoryId: string;
  storeId: string;
}

export interface AddFacetToCategoryAction {
  payload: AddFacetToCategoryPayload;
  type: typeof ADD_FACET_TO_CATEGORY;
}

export interface InsertFacetsToCategoryObject {
  facetId: string;
  storeId: string;
  categoryId: string;
  isEnabled: boolean;
}

export interface FacetData {
  Facet: Facet[];
}

/**
 * Update attribute value sequences type
 */
export const CHANGE_ATTRIBUTE_VALUE_SEQUENCES =
  "CHANGE_ATTRIBUTE_VALUE_SEQUENCES";
export const UPDATE_ATTRIBUTE_VALUE_SEQUENCES = {
  REQUEST: "UPDATE_ATTRIBUTE_VALUE_SEQUENCES_REQUEST",
  SUCCESS: "UPDATE_ATTRIBUTE_VALUE_SEQUENCES_SUCCESS",
  FAILURE: "UPDATE_ATTRIBUTE_VALUE_SEQUENCES_FAILURE",
};

export interface UpdateAttributeValueSequencesPayload {
  valueSequences: AttrValueSequence[];
  localeCode: string | null;
}

export interface UpdateAttributeValueSequencesAction {
  payload: UpdateAttributeValueSequencesPayload;
  type: typeof CHANGE_ATTRIBUTE_VALUE_SEQUENCES;
}

/**
 * Update facet sequences type
 */
export const CHANGE_FACET_SEQUENCES = "CHANGE_FACET_SEQUENCES";
export const UPDATE_FACET_SEQUENCES = {
  REQUEST: "UPDATE_FACET_SEQUENCES_REQUEST",
  SUCCESS: "UPDATE_FACET_SEQUENCES_SUCCESS",
  FAILURE: "UPDATE_FACET_SEQUENCES_FAILURE",
};

export interface FacetSequences {
  facetId: string;
  sequence: number;
}

export interface UpdateFacetSequencesPayload {
  storeId: string;
  categoryId: string;
  facetSequences: FacetSequences[];
}

export interface UpdateFacetSequencesAction {
  payload: UpdateFacetSequencesPayload;
  type: typeof CHANGE_FACET_SEQUENCES;
}

/**
 * Update SFCCC facet sequences type
 */
export const CHANGE_SFCC_FACET_SEQUENCES = "CHANGE_SFCC_FACET_SEQUENCES";
export const UPDATE_SFCC_FACET_SEQUENCES = {
  REQUEST: "UPDATE_SFCC_FACET_SEQUENCES_REQUEST",
  SUCCESS: "UPDATE_SFCC_FACET_SEQUENCES_SUCCESS",
  FAILURE: "UPDATE_SFCC_FACET_SEQUENCES_FAILURE",
};

export interface UpdateSFCCFacetSequencesPayload {
  facetSequences: FacetSequences[];
}

export interface UpdateSFCCFacetSequencesAction {
  payload: UpdateSFCCFacetSequencesPayload;
  type: typeof CHANGE_SFCC_FACET_SEQUENCES;
}

/**
 * Add attribute value facet filter
 */
export const ADD_ATTRIBUTE_VALUE_FACET_FILTER =
  "ADD_ATTRIBUTE_VALUE_FACET_FILTER";

export interface AddAttributeValueFacetFilterAction {
  payload: {
    attributeValueIds: string[];
  };
  type: typeof ADD_ATTRIBUTE_VALUE_FACET_FILTER;
}

/**
 * Remove attribute value facet filter
 */
export const REMOVE_ATTRIBUTE_VALUE_FACET_FILTER =
  "REMOVE_ATTRIBUTE_VALUE_FACET_FILTER";

export interface RemoveAttributeValueFacetFilterAction {
  payload: {
    attributeValueIds: string[];
  };
  type: typeof REMOVE_ATTRIBUTE_VALUE_FACET_FILTER;
}

/**
 * Disable inherited facet
 */
export const CHANGE_INHERITED_FACET = "CHANGE_INHERITED_FACET";
export const UPDATE_INHERITED_FACET = {
  REQUEST: "UPDATE_INHERITED_FACET_REQUEST",
  SUCCESS: "UPDATE_INHERITED_FACET_SUCCESS",
  FAILURE: "UPDATE_INHERITED_FACET_FAILURE",
};

export interface UpdateInheritedFacetPayload {
  facetId: string;
  categoryId: string;
  isEnabled: boolean;
  attributeId: string;
  type: string;
}

export interface UpdateInheritedFacetAction {
  payload: UpdateInheritedFacetPayload;
  type: typeof CHANGE_INHERITED_FACET;
}

/**
 * Update facet bucket sequences type
 */
export const CHANGE_FACET_BUCKET_SEQUENCES = "CHANGE_FACET_BUCKET_SEQUENCES";
export const UPDATE_FACET_BUCKET_SEQUENCES = {
  REQUEST: "UPDATE_FACET_BUCKET_SEQUENCES_REQUEST",
  SUCCESS: "UPDATE_FACET_BUCKET_SEQUENCES_SUCCESS",
  FAILURE: "UPDATE_FACET_BUCKET_SEQUENCES_FAILURE",
};

export interface FacetBucketSequences {
  facetBucketId: string;
  sequence: number;
}

export interface UpdateFacetBucketSequencesPayload {
  facetId: string;
  facetBucketSequences: FacetBucketSequences[];
}

export interface UpdateFacetBucketSequencesAction {
  payload: UpdateFacetBucketSequencesPayload;
  type: typeof CHANGE_FACET_BUCKET_SEQUENCES;
}

/**
 * Update facet bucket description type
 */
export const CHANGE_FACET_BUCKET_DESCRIPTION =
  "CHANGE_FACET_BUCKET_DESCRIPTION";
export const UPDATE_FACET_BUCKET_DESCRIPTION = {
  REQUEST: "UPDATE_FACET_BUCKET_DESCRIPTION_REQUEST",
  SUCCESS: "UPDATE_FACET_BUCKET_DESCRIPTION_SUCCESS",
  FAILURE: "UPDATE_FACET_BUCKET_DESCRIPTION_FAILURE",
};

export interface UpdateFacetBucketDescriptionPayload {
  facetBucketId: string;
  localeCode: string;
  displayValue: string;
}

export interface UpdateFacetBucketDescriptionAction {
  payload: UpdateFacetBucketDescriptionPayload;
  type: typeof CHANGE_FACET_BUCKET_DESCRIPTION;
}

/**
 * Add facet bucket description type
 */
export const ADD_FACET_BUCKET = "ADD_FACET_BUCKET";
export const INSERT_FACET_BUCKET = {
  REQUEST: "INSERT_FACET_BUCKET_REQUEST",
  SUCCESS: "INSERT_FACET_BUCKET_SUCCESS",
  FAILURE: "INSERT_FACET_BUCKET_FAILURE",
};

export interface AddFacetBucketPayload {
  facetBucket: InsertFacetBucketObject;
  facetBucketId: string;
  localeCode: string;
}

export interface InsertFacetBucketObject {
  facetBucketId: string;
  facetId: string;
  isDefault?: boolean;
  threshold?: number;
  sequence: number;
  descriptions: {
    data: FacetBucketDescription;
  } | null;
  values: {
    data: FacetBucketValue;
  } | null;
}

export interface AddFacetBucketAction {
  payload: AddFacetBucketPayload;
  type: typeof ADD_FACET_BUCKET;
}

/**
 * Add facet bucket values type
 */
export const ADD_FACET_BUCKET_VALUE = "ADD_FACET_BUCKET_VALUE";
export const INSERT_FACET_BUCKET_VALUE = {
  REQUEST: "INSERT_FACET_BUCKET_VALUE_REQUEST",
  SUCCESS: "INSERT_FACET_BUCKET_VALUE_SUCCESS",
  FAILURE: "INSERT_FACET_BUCKET_VALUE_FAILURE",
};

export interface AddFacetBucketValuePayload {
  facetBucketId: string;
  bucketValues: string[];
  attributeId: string;
}

export interface AddFacetBucketValueAction {
  payload: AddFacetBucketValuePayload;
  type: typeof ADD_FACET_BUCKET_VALUE;
}

/**
 * Delete facet bucket values type
 */
export const DELETE_FACET_BUCKET_VALUE = "DELETE_FACET_BUCKET_VALUE";
export const REMOVE_FACET_BUCKET_VALUE = {
  REQUEST: "REMOVE_FACET_BUCKET_VALUE_REQUEST",
  SUCCESS: "REMOVE_FACET_BUCKET_VALUE_SUCCESS",
  FAILURE: "REMOVE_FACET_BUCKET_VALUE_FAILURE",
};

export interface DeleteFacetBucketValuePayload {
  facetBucketId: string;
  bucketValues: string[];
  attributeId: string;
}

export interface DeleteFacetBucketValueAction {
  payload: DeleteFacetBucketValuePayload;
  type: typeof DELETE_FACET_BUCKET_VALUE;
}

/**
 * Update facet bucket type
 */
export const CHANGE_FACET_BUCKET = "CHANGE_FACET_BUCKET";
export const UPDATE_FACET_BUCKET = {
  REQUEST: "UPDATE_FACET_BUCKET_REQUEST",
  SUCCESS: "UPDATE_FACET_BUCKET_SUCCESS",
  FAILURE: "UPDATE_FACET_BUCKET_FAILURE",
};

export interface UpdateFacetBucketPayload {
  facetBucketId: string;
  threshold: number;
}

export interface UpdateFacetBucketAction {
  payload: UpdateFacetBucketPayload;
  type: typeof CHANGE_FACET_BUCKET;
}
