import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled, useTheme } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import ButtonComponent from "../common/ButtonComponent";
import { selectStoreList } from "../../store/store-list/StoreListSelectors";
import { UpdateProductPublishedState } from "../../store/product/ProductActions";
import { selectIsProductPublishFlag } from "../../store/product/ProductSelectors";
import AppState from "../../store/AppState";
import {
  selectIsModalOpen,
  selectModalVariable,
} from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { DefaultValue, StoreValue } from "../../store/product/ProductTypes";
import CustomDialog from "../common/DialogContainer";
import { Theme } from "@mui/material";
import DropdownMenu from "../common/DropdownMenu";
import { selectValidProductIds } from "store/clipboard/ClipBoardSelectors";
import {
  selectChildCategories,
  selectCurrentCategory,
  selectTopCategories,
} from "store/category/CategorySelectors";

const styleClasses = {
  overlay: "overlay",
  wrapper: "wrapper",
  actiondiv: "actiondiv",
};

const StyledDiv = styled("div")(({ theme }) => ({
  [`&.${styleClasses.overlay}`]: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 99999,
    cursor: "pointer",
  },
  [`&.${styleClasses.wrapper}`]: {
    position: "relative",
  },
  [`&.${styleClasses.actiondiv}`]: {
    width: "100%",
    textAlign: "end",
    margin: "20px",
  },
}));

const useStyles = (theme: Theme) => {
  return {
    button: {
      margin: theme.spacing(1),
      width: "10rem",
    },
    formControl: {
      display: "grid",
      gridTemplateColumns: "1fr auto",
      alignItems: "center",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      "&  .MuiInput-formControl": {
        marginTop: 0,
        width: "120px",
      },
      "& label": {
        marginRight: "7px",
      },
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    closeBtn: {
      paddingRight: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  };
};

export const MultipleStoreModalId = "MultipleStoreModal";

const MultipleStoreModal: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [defaultState, setDefaultState] = useState<DefaultValue>("No Change");
  const [storeState, setStoreState] = useState<
    {
      storeId: string;
      value: StoreValue;
    }[]
  >([]);
  const storesList = useSelector(selectStoreList);
  const selectedProductIds = useSelector(selectValidProductIds);
  const isPublishedFlag = useSelector(selectIsProductPublishFlag);
  const isOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, MultipleStoreModalId),
  );
  const loading = useSelector((state: AppState) =>
    selectModalVariable(state, MultipleStoreModalId, "loading"),
  );
  const topCategories = useSelector(selectTopCategories);
  const childCategories = useSelector(selectChildCategories);
  const currentCategoryId = useSelector(selectCurrentCategory) || "";
  const disabled =
    storeState.every((store) => store.value === "No Change") &&
    defaultState === "No Change";

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      const state: {
        storeId: string;
        value: StoreValue;
      }[] = storesList.map((store) => ({
        storeId: store.storeId,
        value: "No Change",
      }));
      setStoreState(state);
      setDefaultState("No Change");
    }
  }, [storesList, isOpen]);

  const defaultChangeHandler = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setDefaultState(event.target.value as DefaultValue);
  };

  const storesChangeHandler = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>,
  ) => {
    const { name, value } = event.target;
    const newState = [...storeState];
    const index = storeState.findIndex((store) => store.storeId === name);
    newState[index].value = value as StoreValue;
    setStoreState(newState);
  };

  const submitHandler = () => {
    const tc = topCategories.find((e) => e.categoryId === currentCategoryId);
    const ccFilter = Object.entries(childCategories).find((e) =>
      e[1].childCategories.find((el) => el.categoryId === currentCategoryId),
    );
    dispatch(setModalState(MultipleStoreModalId, true, { loading: true }));
    let payload;
    let filteredStoreState = storeState.filter(
      (store) => store.value !== "No Change",
    );
    if (defaultState !== "No Change") {
      const defaultStore = [
        {
          storeId: "default",
          value: defaultState,
        },
      ];
      const allStoreState = [...filteredStoreState, ...defaultStore];
      payload = {
        productIds: selectedProductIds,
        storeState: allStoreState,
        parentId: !tc && ccFilter ? ccFilter[0] : null,
      };
      dispatch(UpdateProductPublishedState(payload));
    } else {
      payload = {
        productIds: selectedProductIds,
        storeState: filteredStoreState,
        parentId: !tc && ccFilter ? ccFilter[0] : null,
      };
      dispatch(UpdateProductPublishedState(payload));
    }
  };

  const closeHandler = () => {
    dispatch(setModalState(MultipleStoreModalId, false));
  };

  const multipleStoreModalTitle = () => {
    return (
      <>
        <Typography variant="inherit">
          Manage publish status in multi stores
        </Typography>
        {isPublishedFlag && (
          <StyledDiv className={styleClasses.overlay}>
            <CircularProgress />
          </StyledDiv>
        )}
      </>
    );
  };

  const multipleStoreModalContent = () => {
    return (
      <>
        <FormControl style={classes.formControl}>
          <label>
            <Typography color="textPrimary">Default: </Typography>
          </label>
          <DropdownMenu
            menuId="MultipleStoreModal"
            value={defaultState}
            name="Default"
            changeHandler={defaultChangeHandler}
          />
        </FormControl>
        <Divider style={classes.divider} />
        {storeState.map((store) => (
          <FormControl style={classes.formControl} key={store.storeId}>
            <label>
              <Typography color="textPrimary">{store.storeId}: </Typography>
            </label>
            <DropdownMenu
              menuId="MultipleStoreModal"
              value={store.value}
              name={store.storeId}
              changeHandler={storesChangeHandler}
            />
          </FormControl>
        ))}
      </>
    );
  };

  const dialogActions = () => {
    return (
      <StyledDiv className={styleClasses.actiondiv}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={closeHandler}
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={submitHandler}
          disabled={loading || disabled}
        >
          Submit
        </ButtonComponent>
      </StyledDiv>
    );
  };

  return (
    <CustomDialog
      open={isOpen}
      onClose={closeHandler}
      title={multipleStoreModalTitle()}
      fullWidth
      maxWidth={"sm"}
      actions={dialogActions()}
    >
      {multipleStoreModalContent()}
    </CustomDialog>
  );
};

export default MultipleStoreModal;
