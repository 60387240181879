export const LOAD_TOP_CATEGORIES = "LOAD_TOP_CATEGORIES";
export const LOAD_TOP_CATEGORIES_FOR_REPORT = "LOAD_TOP_CATEGORIES_FOR_REPORT";
export const LOAD_CHILD_CATEGORIES = "LOAD_CHILD_CATEGORIES";
export const LOAD_PARENT_CHILD_CATEGORIES = "LOAD_PARENT_CHILD_CATEGORIES";
export const LOAD_CHILD_CATEGORIES_FOR_REPORT =
  "LOAD_CHILD_CATEGORIES_FOR_REPORT";
export const RESET_CATEGORY = "RESET_CATEGORY";
export const CLOSE_ALL_CATEGORY_SUBSCRIPTIONS =
  "CLOSE_ALL_CATEGORY_SUBSCRIPTIONS";
export const UPDATE_CATEGORY_PRODUCT_COUNT = "UPDATE_CATEGORY_PRODUCT_COUNT";
export const FETCH_PRODUCT_COUNT = {
  REQUEST: "FETCH_PRODUCT_COUNT_REQUEST",
  SUCCESS: "FETCH_PRODUCT_COUNT_SUCCESS",
  FAILURE: "FETCH_PRODUCT_COUNT_FAILURE",
};
export const FETCH_TOP_CATEGORIES = {
  REQUEST: "FETCH_TOP_CATEGORIES_REQUEST",
  SUCCESS: "FETCH_TOP_CATEGORIES_SUCCESS",
  FAILURE: "FETCH_TOP_CATEGORIES_FAILURE",
};

export const FETCH_TOP_CATEGORIES_FOR_REPORT = {
  REQUEST: "FETCH_TOP_CATEGORIES_FOR_REPORT_REQUEST",
  SUCCESS: "FETCH_TOP_CATEGORIES_FOR_REPORT_SUCCESS",
  FAILURE: "FETCH_TOP_CATEGORIES_FOR_REPORT_FAILURE",
};

export const FETCH_CHILD_CATEGORIES = {
  REQUEST: "FETCH_CHILD_CATEGORIES_REQUEST",
  SUCCESS: "FETCH_CHILD_CATEGORIES_SUCCESS",
  FAILURE: "FETCH_CHILD_CATEGORIES_FAILURE",
};
export const FETCH_PARENT_CHILD_CATEGORIES = {
  REQUEST: "FETCH_PARENT_CHILD_CATEGORIES_REQUEST",
  SUCCESS: "FETCH_PARENT_CHILD_CATEGORIES_SUCCESS",
  FAILURE: "FETCH_PARENT_CHILD_CATEGORIES_FAILURE",
};

export const FETCH_CHILD_CATEGORIES_FOR_REPORT = {
  REQUEST: "FETCH_CHILD_CATEGORIES_FOR_REPORT_REQUEST",
  SUCCESS: "FETCH_CHILD_CATEGORIES_FOR_REPORT_SUCCESS",
  FAILURE: "FETCH_CHILD_CATEGORIES_FOR_REPORT_FAILURE",
};

export const UPDATE_TOP_CATEGORIES_LOADING_STATE =
  "UPDATE_TOP_CATEGORIES_LOADING_STATE";
export const UPDATE_TOP_CATEGORIES_LOADING_STATE_VALUE =
  "UPDATE_TOP_CATEGORIES_LOADING_STATE_VALUE";
export const RESET_TOP_CATEGORIES_LOADING_STATE =
  "RESET_TOP_CATEGORIES_LOADING_STATE";
export const RESET_TOP_CATEGORIES_LOADING_STATE_VALUE =
  "RESET_TOP_CATEGORIES_LOADING_STATE_VALUE";

export const FETCH_TOP_CATEGORIES_FOR_FIND_PRODUCTS = {
  REQUEST: "FETCH_TOP_CATEGORIES_FOR_FIND_PRODUCTS_REQUEST",
  SUCCESS: "FETCH_TOP_CATEGORIES_FOR_FIND_PRODUCTS_SUCCESS",
  FAILURE: "FETCH_TOP_CATEGORIES_FOR_FIND_PRODUCTS_FAILURE",
};

export const LOAD_TOP_CATEGORIES_FOR_FIND_PRODUCTS =
  "LOAD_TOP_CATEGORIES_FOR_FIND_PRODUCTS";

export const FETCH_CHILD_CATEGORIES_FOR_FIND_PRODUCTS = {
  REQUEST: "FETCH_CHILD_CATEGORIES_FOR_FIND_PRODUCTS_REQUEST",
  SUCCESS: "FETCH_CHILD_CATEGORIES_FOR_FIND_PRODUCTS_SUCCESS",
  FAILURE: "FETCH_CHILD_CATEGORIES_FOR_FIND_PRODUCTS_FAILURE",
};

export const LOAD_CHILD_CATEGORIES_FOR_FIND_PRODUCTS =
  "LOAD_CHILD_CATEGORIES_FOR_FIND_PRODUCTS";

export const SET_CATEGORY_EXPAND = "SET_CATEGORY_EXPAND";
export const RESET_CATEGORY_EXPAND = "RESET_CATEGORY_EXPAND";
export const SET_TOP_CATEGORIES_PROGRESS_STATE =
  "SET_TOP_CATEGORIES_PROGRESS_STATE";
export interface ResetCategory {
  type: typeof RESET_CATEGORY;
}

export interface CloseAllCategorySubscriptionsAction {
  type: typeof CLOSE_ALL_CATEGORY_SUBSCRIPTIONS;
  payload: {
    catalog: string;
  };
}

export interface LoadTopCategoriesAction {
  payload: GetTopCategoriesPayLoad;
  type: typeof LOAD_TOP_CATEGORIES;
}

export interface LoadTopCategoriesResponseAction {
  payload: GetTopCategoriesResponsePayLoad;
  type: string;
}

export interface LoadChildCategoriesResponseAction {
  payload: GetChildCategoriesResponsePayLoad;
  type: string;
}

export type CategoryActionTypes =
  | LoadTopCategoriesResponseAction
  | LoadChildCategoriesResponseAction
  | SetAdditionalAttributesAction
  | SetCurrentCategoryAction;

export interface LoadChildCategoriesAction {
  payload: GetChildCategoriesPayLoad;
  type: typeof LOAD_CHILD_CATEGORIES | typeof LOAD_PARENT_CHILD_CATEGORIES;
}

export interface CategorytoProductsAggregate {
  categoryId: string;
  code: string;
  name: string;
  isPublished: boolean;
  parentId: number;
  hasSubcategories: boolean;
  hasProducts: boolean;
  productCount: number;
}

export interface Translations {
  name: string;
  isPublished: boolean;
  localeCode: string;
}

export interface Category {
  categoryId: string;
  catalogId: string;
  category: CategorytoProductsAggregate;
}

interface Count {
  count: number;
}

export interface categoryToChildCategoriesAggregate {
  code: string;
  toChildCategories_aggregate: countAggregate;
  toProducts_aggregate: countAggregate;
  translations: Translations[];
  isPublished: boolean;
}

interface countAggregate {
  aggregate: Count;
  nodes: ProductPublishNode[];
}

interface ProductPublishNode {
  product: ProductPublish;
}

interface ProductPublish {
  isPublished: boolean;
  descriptions: PublishData[];
  overrides: PublishData[];
}

interface PublishData {
  isPublished: boolean;
  storeId: string;
}

export interface TopCategoriesData {
  categoryId: string;
  code: string;
  name: string;
  isPublished: boolean;
  parentId: string;
  hasSubcategories: boolean;
  hasProducts: boolean;
  productCount: number;
  childCategoryCount: number;
  translations: Translations[];
}

export interface ChildCategoriesData {
  categoryId: string;
  code: string;
  name: string;
  isPublished: boolean;
  parentId: string;
  hasSubcategories: boolean;
  hasProducts: boolean;
  productCount: number;
  childCategoryCount: number;
  translations: Translations[];
}

export interface ProductCountData {
  CategoryToProduct_aggregate: countAggregate;
}

export interface GetTopCategoriesResponsePayLoad {
  count: number;
  total: number;
  results: TopCategoriesData[];
}

interface Variables {
  categoryId: string;
  catalogId: string;
}

export interface GetChildCategoriesResponsePayLoad {
  count: number;
  total: number;
  results: ChildCategoriesData[];
}

export interface GetProductCountResponsePayLoad {
  variables: Variables;
  data: ProductCountData;
}

export interface GetProductAttributesByCategoryResponsePayLoad {
  data: Attribute;
}

export interface Attribute {
  Attribute: AttributeData[];
}

export interface AttributeData {
  isMultiValue: boolean;
  attributeId: string;
  code: string;
  descriptions: AttributeDescription[];
  values: AttributeValue[];
  isStoreSpecific: boolean;
  isLocalized: boolean;
}

interface AttributeDescription {
  displayName: string;
  localeCode: string;
}

export interface AttributeValueDescription {
  displayValue: string;
  localeCode: string;
  sequence: number;
}

export interface AttributeValue {
  attributeValueId: string;
  code: string;
  sequence: number;
  descriptions: AttributeValueDescription[];
}

export interface GetTopCategoriesPayLoad {
  catalogId: string;
  localeId: string;
  storeId: string;
}

export interface GetChildCategoriesPayLoad {
  catalogId: string;
  categoryId: string;
  localeId: string;
  storeId: string;
}

export interface UpdateCategoryProductCountPayload {
  categoryId: string[];
  increment: boolean;
  count: number;
}

export interface GetAttributesPayLoad {
  catalogId: string;
  categoryId: string;
  storeId: string;
}

/**
 * Set additional attribute types
 */
export const SET_ADDITIONAL_ATTRIBUTES = "SET_ADDITIONAL_ATTRIBUTES";

export interface SetAdditionalAttributesPayload {
  additionalAttributesData: AttributeData[];
}

export interface SetAdditionalAttributesAction {
  type: typeof SET_ADDITIONAL_ATTRIBUTES;
  payload: SetAdditionalAttributesPayload;
}

/**
 * Set Current current category
 */
export const SET_CURRENT_CATEGORY = "SET_CURRENT_CATEGORY";

export interface SetCurrentCategoryAction {
  type: typeof SET_CURRENT_CATEGORY;
  payload: {
    categoryId: string;
  };
}

export interface LoadTopCategoriesForReportAction {
  payload: GetTopCategoriesPayLoad;
  type: typeof LOAD_TOP_CATEGORIES_FOR_REPORT;
}

export interface LoadChildCategoriesForReportAction {
  payload: GetChildCategoriesPayLoad;
  type: typeof LOAD_CHILD_CATEGORIES_FOR_REPORT;
}

export interface LoadTopCategoriesForFindProducts {
  payload: GetTopCategoriesPayLoad;
  type: typeof LOAD_TOP_CATEGORIES_FOR_FIND_PRODUCTS;
}

export interface LoadChildCategoriesActionForFindProducts {
  payload: GetChildCategoriesPayLoad;
  type: typeof LOAD_CHILD_CATEGORIES_FOR_FIND_PRODUCTS;
}
