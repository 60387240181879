import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import ButtonComponent from "../../components/common/ButtonComponent";
import {
  selectProductFetched,
  selectIsProductChildrenFetched,
} from "../../store/product/ProductSelectors";
import AppState from "../../store/AppState";
import {
  selectIsModalOpen,
  selectModalVariable,
} from "../../store/modal/ModalSelectors";
import { CloseModal, setModalState } from "../../store/modal/ModalActions";
import ItemManagementSFCCTable from "./ItemManagementSFCCTable";

import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import { selectCurrentLocale } from "../../store/store-list/StoreListSelectors";
import { getProductName } from "../../utils/ProductUtil";
import CustomDialog from "../common/DialogContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: theme.palette.grey[200],
      marginBottom: "24px",
      padding: "0 0 0 24px",
    },
    dialogContent: {
      padding: "0 24px",
      textAlign: "center",
    },
    button: {
      margin: theme.spacing(1),
      width: "10rem",
    },
    typography: {
      margin: theme.spacing(1),
    },
    dialogAction: {
      width: "100%",
      textAlign: "end",
      margin: "20px",
    },
  }),
);

export const itemManagementSFCCModalId = "ItemManagementSFCCModal";

function ItemManagementSFCCModal() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const localeId = useSelector(selectCurrentLocale) || "";
  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );

  const productId = useSelector((state: AppState) =>
    selectModalVariable(state, itemManagementSFCCModalId, "productId"),
  );

  const open = useSelector((state: AppState) =>
    selectIsModalOpen(state, itemManagementSFCCModalId),
  );

  const fetchedProduct = useSelector((state: AppState) =>
    selectProductFetched(state, productId),
  );
  const IsProductChildrenFetched = useSelector(selectIsProductChildrenFetched);

  const handleClose = () => {
    dispatch(setModalState(itemManagementSFCCModalId, false));
    dispatch(CloseModal({ productId: productId }));
  };
  const itemManagementSFCCModalTitle = () => {
    return (
      <Typography variant="inherit">
        Item Management
        {fetchedProduct
          ? ` - ${getProductName(fetchedProduct, localeId, defaultLocaleId)}`
          : ""}
      </Typography>
    );
  };

  const itemManagementSFCCModalContent = () => {
    return (
      <>
        {IsProductChildrenFetched ? (
          <ItemManagementSFCCTable parentId={productId} />
        ) : (
          <CircularProgress color="secondary" />
        )}
      </>
    );
  };

  const dialogActions = () => {
    return (
      <div className={classes.dialogAction}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleClose}
        >
          Close
        </ButtonComponent>
      </div>
    );
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      title={itemManagementSFCCModalTitle()}
      actions={dialogActions()}
      maxWidth={"lg"}
      fullWidth
    >
      {itemManagementSFCCModalContent()}
    </CustomDialog>
  );
}

export default React.memo(ItemManagementSFCCModal);
