export const FETCH_COLOR_MANAGEMENT_PRODUCT_LIST = {
  REQUEST: "FETCH_COLOR_MANAGEMENT_PRODUCT_LIST_REQUEST",
  SUCCESS: "FETCH_COLOR_MANAGEMENT_PRODUCT_LIST_SUCCESS",
  FAILURE: "FETCH_COLOR_MANAGEMENT_PRODUCT_LIST_FAILURE",
};

export const UPDATE_PRODUCT_COLOR_IDS_SEQUENCE = {
  REQUEST: "UPDATE_PRODUCT_COLOR_IDS_SEQUENCE_REQUEST",
  SUCCESS: "UPDATE_PRODUCT_COLOR_IDS_SEQUENCE_SUCCESS",
  FAILURE: "UPDATE_PRODUCT_COLOR_IDS_SEQUENCE_FAILURE",
};

export const UPDATE_COLOR_MANAGEMENT_PUBLISH_FLAG = {
  REQUEST: "UPDATE_COLOR_MANAGEMENT_PUBLISH_FLAG_REQUEST",
  SUCCESS: "UPDATE_COLOR_MANAGEMENT_PUBLISH_FLAG_SUCCESS",
  FAILURE: "UPDATE_COLOR_MANAGEMENT_PUBLISH_FLAG_FAILURE",
};

export const UPDATE_CATEGORY_LEADING_COLOR_FOR_PRODUCT = {
  REQUEST: "UPDATE_CATEGORY_LEADING_COLOR_FOR_PRODUCT_REQUEST",
  SUCCESS: "UPDATE_CATEGORY_LEADING_COLOR_FOR_PRODUCT_SUCCESS",
  FAILURE: "UPDATE_CATEGORY_LEADING_COLOR_FOR_PRODUCT_FAILURE",
};

export const FETCH_COLOR_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID = {
  REQUEST: "FETCH_COLOR_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_REQUEST",
  SUCCESS: "FETCH_COLOR_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_SUCCESS",
  FAILURE: "FETCH_COLOR_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_FAILURE",
};

export interface UpdateProductColorIdsPayload {
  colorIds: string[];
}

export interface UpdateProductColorPayload {
  productId: string;
  colorIds: string[];
}

export interface GetColorManagementProductListPayload {
  productList: ColorData[];
}

export interface UpdateProductColorAction {
  payload: UpdateProductColorPayload;
  type: typeof UPDATE_PRODUCT_COLOR_SEQUENCE;
}

export interface ToggleSequenceEditedAction {
  type: typeof TOGGLE_SEQUENCE_EDITED;
}

export interface UpdateLeadingColorProductAction {
  type: typeof UPDATE_CATEGORY_LEADING_COLOR_FOR_PRODUCT;
  payload: LeadingColorPayload;
}

export interface LeadingColorPayload {
  leadingColorId: string;
  categoryId: string;
  productId: string;
  thumbnail: string;
}

export interface GetVariationAttributesPayload {
  baseProductId: string;
}

export interface GetVariantAttributesAction {
  type: typeof FETCH_COLOR_VARIATION_ATTRIBUTES;
  payload: GetVariationAttributesPayload;
}

export interface GetColorManagementProductListAction {
  payload: GetColorManagementProductListPayload;
  type: typeof FETCH_COLOR_MANAGEMENT_LIST;
}

export const RESET_COLOR_MANAGEMENT_PRODUCT_LIST =
  "RESET_COLOR_MANAGEMENT_PRODUCT_LIST";
export const FETCH_COLOR_VARIATION_ATTRIBUTES =
  "FETCH_COLOR_VARIATION_ATTRIBUTES";
export const FETCH_COLOR_MANAGEMENT_LIST = "FETCH_COLOR_MANAGEMENT_LIST";
export const UPDATE_PRODUCT_COLOR_SEQUENCE = "UPDATE_PRODUCT_COLOR_SEQUENCE";
export const TOGGLE_SEQUENCE_EDITED = "TOGGLE_SEQUENCE_EDITED";
export const UPDATE_COLOR_PUBLISH_FLAG_COMPLETE =
  "UPDATE_COLOR_PUBLISH_FLAG_COMPLETE";
export const FETCH_COLOR_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_COMPLETE =
  "FETCH_COLOR_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_COMPLETE";
export const UPDATE_LEADING_COLOR = "UPDATE_LEADING_COLOR";
export const SET_LEADING_COLOR_IMAGE = "SET_LEADING_COLOR_IMAGE";
export const RESET_PRODUCT_LEADING_COLOR = "RESET_PRODUCT_LEADING_COLOR";
export const RESET_PRODUCT_UPDATE_LEADING_COLOR_STATE =
  "RESET_PRODUCT_UPDATE_LEADING_COLOR_STATE";

export const SET_LEADING_COLOR_ID = "SET_LEADING_COLOR_ID";

export interface ColorManagementProductData {
  type?: string;
  colorId?: string;
  code: string;
  name: string;
  swatchImage?: string;
  baseProductId?: string | undefined;
  thumbnail?: string;
  isPublished: boolean;
  storeSpecific?:
    | { storeId: string; isPublished: boolean | null }[]
    | undefined;
  translations: [
    {
      localeCode: string;
      name: string;
    },
  ];
  stock: Number;
  hasSequenceableColors?: boolean;
  price?: Number;
  bounds?: ClientRect | DOMRect | null;
  categorySpecific?: categorySpecificData[];
  isUpdatingPublishFlag?: boolean;
  colors?: ColorData[];
}

export interface ColorData {
  sequence: string;
  name: string;
  swatchImage: string;
  thumbnail: string;
  code: string;
  colorId: string;
  isPublished: boolean;
  translations: [
    {
      localeCode: string;
      name: string;
    },
  ];
  stock: number;
  price: number;
  isUpdatingPublishFlag?: boolean;
  storeSpecific: { storeId: string; isPublished: boolean | null }[];
}

export interface categorySpecificData {
  categoryId: string;
  leadingColorId?: string;
  leadingImageId?: string;
}

export interface LeadingColorImage {
  thumbnail: string;
  color: string;
}

export interface leadingColorId {
  leadingColorId: string;
}
