import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import FacetsList from "./FacetsList";
import { useDispatch } from "react-redux";
import { setModalState } from "../../store/modal/ModalActions";
import { AddFacetModalId } from "./AddFacetModal";
import Icons from "components/common/Icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overlayHeader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: " 44px",
      width: 228,
      backgroundColor: "#fafafa",
      margin: "0 5px",
      position: "absolute",
      zIndex: 2,
    },
    menuButton: {
      position: "absolute",
      left: 0,
      padding: "10px",
      height: 45,
      width: 45,
      marginRight: 5,
    },
    addButton: {
      position: "absolute",
      padding: "10px",
    },
    hide: {
      display: "none",
    },
    header: {
      fontWeight: 600,
    },
    facetsPanel: {
      width: "250px",
      flexShrink: 0,
      background: " #fafafa",
      boxShadow: "2px 1px 1px rgba(0, 0, 0, 0.1)",
      padding: "0 6px",
      paddingBottom: "5px",
      overflowX: "hidden",
    },
    list: {
      width: "100%",
      padding: theme.spacing(1, 3),
    },
  }),
);

interface Props {
  onDrawerOpen: () => void;
  isOpen: boolean;
}

const FacetsContainer = ({ onDrawerOpen, isOpen }: Props) => {
  const classes = useStyles();
  const DrawerOpenHandler = () => {
    onDrawerOpen();
  };
  const dispatch = useDispatch();

  const handleAddFacetIconClick = () => {
    dispatch(setModalState(AddFacetModalId, true, {}));
  };

  return (
    <>
      <div className={classes.facetsPanel}>
        <div className={classes.overlayHeader}>
          <IconButton
            aria-label="open drawer"
            onClick={DrawerOpenHandler}
            className={clsx(classes.menuButton, isOpen && classes.hide)}
          >
            <Icons iconId="RightArrowSmallIcon" />
          </IconButton>
          <IconButton
            aria-label="open drawer"
            className={classes.addButton}
            onClick={handleAddFacetIconClick}
          >
            <Icons iconId="AddCircleIcon" />
          </IconButton>
        </div>
        <FacetsList />
      </div>
    </>
  );
};

export default React.memo(FacetsContainer);
