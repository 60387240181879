import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, useField } from "formik";
import moment from "moment-timezone";
import { selectIsModalOpen } from "../../../store/modal/ModalSelectors";
import AppState from "../../../store/AppState";
import { useIntl } from "react-intl";
import { setModalState } from "../../../store/modal/ModalActions";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@mui/material";
import CustomDialog from "../../common/DialogContainer";
import { Box, CircularProgress } from "@material-ui/core";
import { SelectChangeEvent } from "@mui/material";
import ButtonComponent from "../../common/ButtonComponent";
import * as Yup from "yup";
import {
  cancelProductAlertsSchedule,
  scheduleProductAlertsJob,
} from "../../../store/product-alerts-schedule-modal/ProductAlertsScheduleAction";
import {
  selectIsFetchingProductAlertsSchedule,
  selectIsUpdatingProductAlertSchedule,
  selectProductAlertsScheduleData,
} from "../../../store/product-alerts-schedule-modal/ProductAlertsScheduleSelectors";
import DropdownMenu from "../../common/DropdownMenu";
import {
  styleClasses,
  StyledBox,
  StyledDiv,
  StyledToggleBox,
  StyledToggleDiv,
  StyledToggleOffButton,
  StyledToggleOnButton,
  useStyles,
} from "./ProductAlertsScheduleStyles";
import { ProductAlertsScheduleInfo } from "store/product-alerts-schedule-modal/ProductAlertsScheduleType";

type Key = string | number;

type SelectProps = {
  name: string | Number;
  label: string;
  menuOptions: Key[];
  width: string;
  defaultValue?: string;
};

const hours = Array.from({ length: 24 }, (_, i) => i); // Hours from 0 to 23
const minutes = Array.from({ length: 60 }, (_, i) => i); // Minutes from 0 to 59
const timezones = moment.tz.names();
const currentUserTimezone = moment.tz.guess();

const validationSchema = Yup.object({
  hour: Yup.string().required("Required"),
  minute: Yup.string().required("Required"),
  timezone: Yup.string().required("Required"),
});

function ProductAlertsScheduleModal() {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const [toggleType, setToggleType] = useState<string>("off");
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "ProductAlertsScheduleModal"),
  );
  const isFecthingDetails = useSelector(selectIsFetchingProductAlertsSchedule);
  const isUpdatingScheduleData = useSelector(
    selectIsUpdatingProductAlertSchedule,
  );
  const selectProductAlertsSchedule = useSelector(
    selectProductAlertsScheduleData,
  );

  const [selectedHour, setSelectedHour] = useState<Number | null>(null);
  const [selectedMinutes, setSelectedMinutes] = useState<Number | null>(null);
  const [selectedTimezone, setSelectedTimezone] = useState<string>("");
  const [initialState, setInitialState] = useState<ProductAlertsScheduleInfo>({
    hour: null,
    minute: null,
    timezone: currentUserTimezone,
  });

  const enableScheduleButton = useMemo(() => {
    const isProductAlertDataUpdated =
      selectedHour !== selectProductAlertsSchedule.hour ||
      selectedMinutes !== selectProductAlertsSchedule.minute ||
      selectedTimezone !== selectProductAlertsSchedule.timezone;
    return (
      selectedHour !== null &&
      selectedMinutes !== null &&
      selectedTimezone !== "" &&
      isProductAlertDataUpdated
    );
  }, [
    selectedHour,
    selectedMinutes,
    selectedTimezone,
    selectProductAlertsSchedule,
  ]);

  const handleClose = useCallback(() => {
    updateProductAlertScheduleData(null, null, "");
    dispatch(setModalState("ProductAlertsScheduleModal", false));
    if (!selectProductAlertsSchedule.hour) setToggleType("off");
  }, [dispatch, selectProductAlertsSchedule.hour]);

  const deleteExistingSchedule = useCallback(() => {
    dispatch(cancelProductAlertsSchedule());
    updateProductAlertScheduleData(null, null, "");
    setInitialState({
      hour: null,
      minute: null,
      timezone: currentUserTimezone,
    });
  }, [dispatch]);

  useEffect(() => {
    if (
      selectedHour === null &&
      selectedMinutes === null &&
      selectedTimezone === "" &&
      !isUpdatingScheduleData
    ) {
      updateProductAlertScheduleData(
        selectProductAlertsSchedule.hour,
        selectProductAlertsSchedule.minute,
        selectProductAlertsSchedule.timezone,
      );
    }
  }, [
    selectProductAlertsSchedule,
    selectedHour,
    selectedMinutes,
    selectedTimezone,
    isUpdatingScheduleData,
  ]);

  useEffect(() => {
    if (selectProductAlertsSchedule.hour !== null) {
      setToggleType("on");
      setInitialState(selectProductAlertsSchedule);
    }
  }, [selectProductAlertsSchedule]);

  const updateProductAlertScheduleData = (
    hour: Number | null,
    minute: Number | null,
    timezone: string,
  ) => {
    setSelectedHour(hour);
    setSelectedMinutes(minute);
    setSelectedTimezone(timezone);
  };
  const productAlersScheduleModalHeader = useCallback(() => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "productAlertsScheduleModal.modalTitle",
          defaultMessage: "Product Alert Schedule",
        })}
      </Typography>
    );
  }, [intl]);

  const SelectWrapper = ({
    name,
    label,
    menuOptions = [],
    width = "",
    defaultValue,
  }: SelectProps) => {
    const [field, meta, helpers] = useField(name as any);
    const { value } = field;
    const { setValue } = helpers;
    useEffect(() => {
      if (
        name === "timezone" &&
        defaultValue !== "" &&
        typeof defaultValue === "string" &&
        selectedTimezone === ""
      ) {
        setValue(defaultValue);
        setSelectedTimezone(defaultValue);
      }
    }, [defaultValue, name, setValue]);
    const handleChange = (event: SelectChangeEvent<string | Number>) => {
      if (label === "Minute") {
        setSelectedMinutes(Number(event.target.value));
      }
      if (label === "Hour") {
        setSelectedHour(Number(event.target.value));
      }
      if (label === "Timezone") {
        setSelectedTimezone(event.target.value as string);
      }
      setValue(event.target.value);
    };

    return (
      <>
        <DropdownMenu
          menuId="ProductAlertScheduleModal"
          value={value}
          label={label}
          changeHandler={handleChange}
          menuOptions={menuOptions}
        />
        {meta.touched && meta.error ? (
          <div style={{ color: "red", fontSize: "12px" }}>{meta.error}</div>
        ) : null}
      </>
    );
  };

  const handleToggleChange = useCallback(
    (toggle: string) => {
      if (toggle === "off" && selectProductAlertsSchedule.hour !== null) {
        deleteExistingSchedule();
      }
      setToggleType(toggle);
    },
    [setToggleType, selectProductAlertsSchedule.hour, deleteExistingSchedule],
  );

  const productAlertModalDescription = (id: string, message: string) => (
    <StyledDiv className={styleClasses.message}>
      <Typography style={classes.productAlertsModalDescription} variant="body1">
        {intl.formatMessage({
          id: id,
          defaultMessage: message,
        })}
      </Typography>
    </StyledDiv>
  );

  const productAlersScheduleModalContent = () => (
    <div>
      {isFecthingDetails ? (
        <StyledDiv className={styleClasses.overlay}>
          <CircularProgress />
        </StyledDiv>
      ) : (
        <>
          {isUpdatingScheduleData && (
            <StyledDiv className={styleClasses.overlay}>
              <CircularProgress />
            </StyledDiv>
          )}
          <StyledToggleDiv className={styleClasses.productToggle}>
            {toggleType === "on"
              ? productAlertModalDescription(
                  "productAlertsScheduleModal.modalDescription",
                  "Please enter the time when the daily job to generate product alerts should run",
                )
              : productAlertModalDescription(
                  "productAlertsScheduleModal.toggle",
                  "Please turn on the product alert and schedule it.",
                )}
            <Box sx={classes.productAlertToggleBox}>
              <StyledToggleBox className={styleClasses.toggleBox}>
                <StyledBox
                  toggleType={toggleType}
                  className={styleClasses.box}
                />
                <StyledToggleOnButton
                  className={styleClasses.toggleOn}
                  toggleType={toggleType}
                  disableRipple
                  variant="text"
                  onClick={() => handleToggleChange("on")}
                >
                  {intl.formatMessage({
                    id: "productAlertsScheduleModal.toggleOn",
                    defaultMessage: "on",
                  })}
                </StyledToggleOnButton>
                <StyledToggleOffButton
                  className={styleClasses.toggleOff}
                  toggleType={toggleType}
                  disableRipple
                  variant="text"
                  onClick={() => handleToggleChange("off")}
                >
                  {intl.formatMessage({
                    id: "productAlertsScheduleModal.toggleOff",
                    defaultMessage: "off",
                  })}
                </StyledToggleOffButton>
              </StyledToggleBox>
            </Box>
          </StyledToggleDiv>
          <Formik
            enableReinitialize
            initialValues={toggleType === "off" ? {} : initialState}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const payload = {
                hour: Number(selectedHour),
                minute: Number(selectedMinutes),
                timezone: selectedTimezone,
              };
              dispatch(scheduleProductAlertsJob(payload));
            }}
          >
            {(_formik) => {
              return (
                <div>
                  <Form>
                    {toggleType === "on" ? (
                      <StyledDiv
                        className={styleClasses.productAlertsScheduleContainer}
                      >
                        <SelectWrapper
                          name="hour"
                          menuOptions={hours}
                          label="Hour"
                          width="100px"
                        />
                        <SelectWrapper
                          name="minute"
                          menuOptions={minutes}
                          label="Minute"
                          width="100px"
                        />
                        <SelectWrapper
                          name="timezone"
                          menuOptions={timezones}
                          label="Timezone"
                          width="150px"
                          defaultValue={currentUserTimezone}
                        />
                      </StyledDiv>
                    ) : (
                      <div style={classes.divHeight}></div>
                    )}
                    <StyledDiv className={styleClasses.buttonContainer}>
                      <ButtonComponent
                        color="secondaryButtonColorCTABlue"
                        variant="outlined"
                        justifyContent="center"
                        onClick={() => handleClose()}
                      >
                        {intl.formatMessage({
                          id: "productAlertsScheduleModal.cancelAction",
                          defaultMessage: "Cancel",
                        })}
                      </ButtonComponent>
                      <ButtonComponent
                        color="primary"
                        variant="contained"
                        justifyContent="center"
                        type="submit"
                        disabled={!enableScheduleButton}
                      >
                        {intl.formatMessage({
                          id: "productAlertsScheduleModal.scheduleAction",
                          defaultMessage: "Schedule",
                        })}
                      </ButtonComponent>
                    </StyledDiv>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </>
      )}
    </div>
  );

  return (
    <Box style={{ maxWidth: "450px" }}>
      <CustomDialog
        open={isModalOpen}
        onClose={handleClose}
        title={productAlersScheduleModalHeader()}
        maxWidth={"md"}
      >
        {productAlersScheduleModalContent()}
      </CustomDialog>
    </Box>
  );
}

export default ProductAlertsScheduleModal;
