import {
  DeleteFacetFromCategoryPayload,
  DeleteFacetFromCategoryAction,
  DELETE_FACET_FROM_CATEGORY,
  AddFacetToCategoryPayload,
  AddFacetToCategoryAction,
  ADD_FACET_TO_CATEGORY,
  UpdateAttributeValueSequencesPayload,
  UpdateAttributeValueSequencesAction,
  CHANGE_ATTRIBUTE_VALUE_SEQUENCES,
  UpdateFacetSequencesPayload,
  UpdateFacetSequencesAction,
  CHANGE_FACET_SEQUENCES,
  AddAttributeValueFacetFilterAction,
  ADD_ATTRIBUTE_VALUE_FACET_FILTER,
  RemoveAttributeValueFacetFilterAction,
  REMOVE_ATTRIBUTE_VALUE_FACET_FILTER,
  UpdateInheritedFacetAction,
  CHANGE_INHERITED_FACET,
  UpdateInheritedFacetPayload,
  UpdateSFCCFacetSequencesPayload,
  UpdateSFCCFacetSequencesAction,
  CHANGE_SFCC_FACET_SEQUENCES,
  UpdateFacetBucketSequencesPayload,
  UpdateFacetBucketSequencesAction,
  CHANGE_FACET_BUCKET_SEQUENCES,
  UpdateFacetBucketDescriptionPayload,
  UpdateFacetBucketDescriptionAction,
  CHANGE_FACET_BUCKET_DESCRIPTION,
  AddFacetBucketPayload,
  AddFacetBucketAction,
  ADD_FACET_BUCKET,
  AddFacetBucketValuePayload,
  AddFacetBucketValueAction,
  ADD_FACET_BUCKET_VALUE,
  DeleteFacetBucketValuePayload,
  DeleteFacetBucketValueAction,
  DELETE_FACET_BUCKET_VALUE,
  UpdateFacetBucketPayload,
  UpdateFacetBucketAction,
  CHANGE_FACET_BUCKET,
} from "./FacetTypes";

export const deleteFacetFromCategory = (
  payload: DeleteFacetFromCategoryPayload,
): DeleteFacetFromCategoryAction => {
  return {
    type: DELETE_FACET_FROM_CATEGORY,
    payload,
  };
};

export const addFacetToCategory = (
  payload: AddFacetToCategoryPayload,
): AddFacetToCategoryAction => {
  return {
    type: ADD_FACET_TO_CATEGORY,
    payload,
  };
};

export const updateAttributeValueSequences = (
  payload: UpdateAttributeValueSequencesPayload,
): UpdateAttributeValueSequencesAction => {
  return {
    type: CHANGE_ATTRIBUTE_VALUE_SEQUENCES,
    payload,
  };
};

export const updateFacetSequences = (
  payload: UpdateFacetSequencesPayload,
): UpdateFacetSequencesAction => {
  return {
    type: CHANGE_FACET_SEQUENCES,
    payload,
  };
};

export const updateSFCCFacetSequences = (
  payload: UpdateSFCCFacetSequencesPayload,
): UpdateSFCCFacetSequencesAction => {
  return {
    type: CHANGE_SFCC_FACET_SEQUENCES,
    payload,
  };
};

export const addAttributeValueFacetFilter = (
  attributeValueIds: string[],
): AddAttributeValueFacetFilterAction => {
  return {
    type: ADD_ATTRIBUTE_VALUE_FACET_FILTER,
    payload: {
      attributeValueIds,
    },
  };
};

export const removeAttributeValueFacetFilter = (
  attributeValueIds: string[],
): RemoveAttributeValueFacetFilterAction => {
  return {
    type: REMOVE_ATTRIBUTE_VALUE_FACET_FILTER,
    payload: {
      attributeValueIds,
    },
  };
};

export const updateInheritedFacet = (
  payload: UpdateInheritedFacetPayload,
): UpdateInheritedFacetAction => {
  return {
    type: CHANGE_INHERITED_FACET,
    payload,
  };
};

export const updateFacetBucketSequences = (
  payload: UpdateFacetBucketSequencesPayload,
): UpdateFacetBucketSequencesAction => {
  return {
    type: CHANGE_FACET_BUCKET_SEQUENCES,
    payload,
  };
};

export const updateFacetBucketDescription = (
  payload: UpdateFacetBucketDescriptionPayload,
): UpdateFacetBucketDescriptionAction => {
  return {
    payload,
    type: CHANGE_FACET_BUCKET_DESCRIPTION,
  };
};

export const addFacetBucket = (
  payload: AddFacetBucketPayload,
): AddFacetBucketAction => {
  return {
    payload,
    type: ADD_FACET_BUCKET,
  };
};

export const addFacetBucketValue = (
  payload: AddFacetBucketValuePayload,
): AddFacetBucketValueAction => {
  return {
    payload,
    type: ADD_FACET_BUCKET_VALUE,
  };
};

export const deleteFacetBucketValue = (
  payload: DeleteFacetBucketValuePayload,
): DeleteFacetBucketValueAction => {
  return {
    payload,
    type: DELETE_FACET_BUCKET_VALUE,
  };
};

export const updateFacetBucket = (
  payload: UpdateFacetBucketPayload,
): UpdateFacetBucketAction => {
  return {
    payload,
    type: CHANGE_FACET_BUCKET,
  };
};
