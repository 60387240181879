import {
  LoadStoreListAction,
  LOAD_STORE_LIST,
  SetCurrentStoreAction,
  SET_CURRENT_STORE,
  SetCurrentLocaleAction,
  SET_CURRENT_LOCALE,
  RESET_STORE_STATE,
} from "./StoreListTypes";

export const LoadStoreList = (): LoadStoreListAction => {
  return {
    type: LOAD_STORE_LIST,
  };
};

export const setCurrentStore = (storeId: string): SetCurrentStoreAction => {
  return {
    type: SET_CURRENT_STORE,
    payload: {
      storeId,
    },
  };
};

export const setCurrentLocale = (
  localeCode: string,
): SetCurrentLocaleAction => {
  return {
    type: SET_CURRENT_LOCALE,
    payload: {
      localeCode,
    },
  };
};

export const resetStoreState = () => {
  return {
    type: RESET_STORE_STATE,
  };
};
