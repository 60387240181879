import {
  LOAD_FIND_PRODUCTS_TOP_CATEGORIES,
  LOAD_FIND_PRODUCTS_CHILD_CATEGORIES,
  LoadFindProductsTopCategoriesAction,
  LoadFindProductsChildCategoriesAction,
  CLOSE_ALL_FIND_PRODUCT_SUBSCRIPTIONS,
  LoadProductsFromCategoryForFindProductsCategoryAction,
  LOAD_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY,
  UpdateProductsCategoryAction,
  CHANGE_PRODUCTS_CATEGORY,
  UpdateProductsCategoryPayload,
  GetProductListPayload,
  FETCH_PRODUCTS_FOR_FIND_PRODUCTS,
  FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY,
} from "./FindProductsTypes";

export const loadFindProductsTopCategoriesByCatalogId = (
  catalogId: string,
  localeId: string,
  storeId: string,
): LoadFindProductsTopCategoriesAction => {
  return {
    type: LOAD_FIND_PRODUCTS_TOP_CATEGORIES,
    payload: {
      catalogId,
      localeId,
      storeId,
    },
  };
};

export const loadFindProductsChildCategories = (
  catalogId: string,
  categoryId: string,
  localeId: string,
  storeId: string,
): LoadFindProductsChildCategoriesAction => {
  return {
    type: LOAD_FIND_PRODUCTS_CHILD_CATEGORIES,
    payload: {
      catalogId,
      categoryId,
      localeId,
      storeId,
    },
  };
};

export const closeFindProductsSubscriptionsAndResetState = (
  catalog: string,
) => ({
  type: CLOSE_ALL_FIND_PRODUCT_SUBSCRIPTIONS,
  payload: {
    catalog,
  },
});

export const loadProductsFromFindProductsCategory = (payload: {
  catalogId: string;
  categoryId: string;
  localeId: string;
  storeId: string;
  orderBy?: string;
}): LoadProductsFromCategoryForFindProductsCategoryAction => {
  return {
    payload,
    type: LOAD_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY,
  };
};

export const updateProductsCategory = (
  payload: UpdateProductsCategoryPayload,
): UpdateProductsCategoryAction => {
  return {
    payload,
    type: CHANGE_PRODUCTS_CATEGORY,
  };
};

export const loadProductsListForFindProductsModal = (
  payload: GetProductListPayload,
) => {
  return {
    payload,
    type: FETCH_PRODUCTS_FOR_FIND_PRODUCTS.REQUEST,
  };
};

export const loadProductIdsFromFindProductsCategory = (
  payload: GetProductListPayload,
) => {
  return {
    payload,
    type: FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY.REQUEST,
  };
};
