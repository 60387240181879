import { createSelector } from "reselect";
import AppState from "../AppState";
import { getCategories } from "../category/CategorySelectors";

export const selectFindProductsState = (state: AppState) => {
  return state.findProducts;
};

export const selectTopCategoriesFromFindProducts = createSelector(
  [selectFindProductsState],
  (findProducts) => {
    return findProducts.topCategory;
  },
);

export const selectChildCategoriesFromFindProducts = createSelector(
  [selectFindProductsState],
  (findProducts) => {
    return findProducts.childCategory;
  },
);

export const selectCategoriesFromFindProducts = createSelector(
  [selectTopCategoriesFromFindProducts, selectChildCategoriesFromFindProducts],
  (catArray, childCategory) => {
    return getCategories(catArray, childCategory, null, []);
  },
);

export const selectProductsFromFindProducts = createSelector(
  [selectFindProductsState],
  (findProducts) => {
    return findProducts.products || [];
  },
);

export const selectProductsSizeFromFindProducts = createSelector(
  [selectProductsFromFindProducts],
  (products) => {
    return products.length;
  },
);

export const selectIsSubmitting = createSelector(
  [selectFindProductsState],
  (findProducts) => {
    return findProducts.isSubmitting;
  },
);

export const selectIsSubmitted = createSelector(
  [selectFindProductsState],
  (findProducts) => {
    return findProducts.isSubmitted;
  },
);

export const selectTopCategoriesForFindProductsModalBasedOnCatalog = (
  state: AppState,
) => {
  return state.category.findProductsModalTopCategory;
};

export const selectChildCategoriesBasedOnCategorySelectionForFindProducts = (
  state: AppState,
) => {
  return state.category.findProductsModalChildCategory;
};

export const selectCategoriesForFindProducts = createSelector(
  [
    selectTopCategoriesForFindProductsModalBasedOnCatalog,
    selectChildCategoriesBasedOnCategorySelectionForFindProducts,
  ],
  (catArray, childCategory) => {
    return getCategories(catArray, childCategory, null, []);
  },
);

export const selectGetProductListForFindProductsModal = (state: AppState) => {
  return state.findProducts.productList;
};

export const selectAreAllPagesFetched = (state: AppState) => {
  return state.findProducts && state.findProducts.allPagesFetched;
};

export const selectGetProductIds = (state: AppState) => {
  return state.findProducts && state.findProducts.productIds;
};

export const selectAddingRemovingProductIdsList = (state: AppState) => {
  return state.findProducts && state.findProducts.addingRemovingProductIdsList;
};

export const selectProductByIdForFindProducts = (state: AppState) => {
  return state.findProducts && state.findProducts.productById;
};
