import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, makeStyles, Toolbar } from "@material-ui/core";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import ProductList from "../ProductList/ProductList";
import {
  isFetchingSortedProducts,
  selectAreAllPagesFetched,
  selectIsProductSequenceUpdateFailure,
  selectIsSequenceLoading,
  selectProductListsFetched,
  selectTotal,
} from "../../store/product-list/ProductListSelectors";
import {
  selectProductListSequenceMode,
  selectProductListEditSequence,
} from "../../store/product-list/ProductListSelectors";
import ProductListActionBtn from "../ProductListActionBtn";
import ProductListSwitch from "../ProductListSwitch";
import ProductListGroupBtn from "../ProductListGroupBtn";
import Box from "@material-ui/core/Box";
import ViewContextProvider from "../../context/view-context";
import ItemDragLayer from "../ItemDragLayer";
import BreadcrumbSequence from "../BreadcrumbSequence";
import {
  selectIsFetchedBySearch,
  selectProductListBySearch,
  selectRecentlyAddedNewProducts,
} from "../../store/product/ProductSelectors";
import { resetProductSequence } from "../../store/product-list/ProductListActions";
import { updateRecentlyAddedProductsAfterSave } from "../../store/product/ProductActions";
import { useQuery } from "../../hooks/useQueryParams";
import { selectIsVerifyingProductCodes } from "../../store/clipboard/ClipBoardSelectors";
import { getTopCategoryProductCount } from "store/category/CategorySelectors";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: "40px",
    padding: 0,
  },
  productCount: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: "20px",
    padding: "2px 0px 0px 0px",
  },
  overlay: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 99999,
    cursor: "pointer",
  },
  loaderClass: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  loaderText: {
    marginRight: 20,
    fontSize: "20px",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#fff",
    padding: "15px",
    borderRadius: "4px",
  },
});

interface Props {}

const ProductListContainer: React.FC<Props> = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const products = useSelector(selectProductListsFetched);
  const editProducts = useSelector(selectProductListEditSequence);
  const productsBySearch = useSelector(selectProductListBySearch);
  const isFetchedBySearch = useSelector(selectIsFetchedBySearch);
  const totalProducts = useSelector(selectTotal);
  const totalPublishedProducts = useSelector(getTopCategoryProductCount);
  const sequenceMode = useSelector(selectProductListSequenceMode);
  const isSequenceLoading = useSelector(selectIsSequenceLoading);
  const recentlyAddedProducts = useSelector(selectRecentlyAddedNewProducts);
  const isFetchingSortedProductsList = useSelector(isFetchingSortedProducts);
  const [progress, setProgress] = useState(false);
  const categoryPathQuery = query.get("categoryPath") ?? "";
  const areAllPagesFetched = useSelector(selectAreAllPagesFetched);

  const isVerifyingProductCodes = useSelector(selectIsVerifyingProductCodes);
  const isProductSequenceUpdateFailure = useSelector(
    selectIsProductSequenceUpdateFailure,
  );

  useEffect(() => {
    if (isSequenceLoading) {
      const categoryIds = categoryPathQuery.split(";");
      const categoryId = categoryIds[categoryIds.length - 1];
      if (recentlyAddedProducts.length) {
        const filteredData = recentlyAddedProducts.filter((data, index) => {
          if (Object.keys(data)[0] === categoryId) {
            // eslint-disable-next-line array-callback-return
            return;
          }
          return data;
        });
        dispatch(updateRecentlyAddedProductsAfterSave(filteredData));
      }

      dispatch(resetProductSequence());
      setProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSequenceLoading]);

  useEffect(() => {
    if (isProductSequenceUpdateFailure) {
      setProgress(false);
    }
  }, [isProductSequenceUpdateFailure]);

  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    // Set initial loading to false after the first page load is complete
    if (products.length > 0) {
      setInitialLoading(false);
    }
  }, [products.length, totalPublishedProducts]);

  const productPanel = (
    <>
      {progress && !isSequenceLoading && (
        <div className={classes.overlay}>
          <h2 style={{ marginRight: "10px" }}>In Progress</h2>
          <CircularProgress />
        </div>
      )}
      <Toolbar className={classes.toolbar}>
        <BreadcrumbSequence />
        <ProductListActionBtn setProgress={setProgress} />
      </Toolbar>
      <Toolbar className={classes.toolbar}>
        <ProductListGroupBtn products={products} />
        <ProductListSwitch />
      </Toolbar>
      <Toolbar className={classes.productCount}>
        {products.length !== 0 && !initialLoading && (
          <Typography variant="heading3_regular" noWrap>
            {intl.formatMessage(
              {
                id: "productListSwitch.publishedProductsText",
                defaultMessage: "{totalProducts} published products",
              },
              { totalProducts: totalPublishedProducts ?? totalProducts ?? 0 },
            )}
          </Typography>
        )}
      </Toolbar>
    </>
  );

  const renderPartNumberVerificationLoader = () => (
    <Box className={classes.overlay}>
      <div className={classes.loaderContainer}>
        <Box className={classes.loaderText}>
          {intl.formatMessage({
            id: "clipBoard.validatingPartNumbers",
            defaultMessage: "Validating Part Numbers...",
          })}
        </Box>
        <Box>
          <CircularProgress size={25} />
        </Box>
      </div>
    </Box>
  );

  return (
    <>
      <ViewContextProvider>
        {productPanel}
        {isVerifyingProductCodes && renderPartNumberVerificationLoader()}
        {areAllPagesFetched && products.length === 0 ? (
          <Box position="absolute" top="50%" left="50%">
            <Typography variant="h5">
              {intl.formatMessage({
                id: "productListSwitch.noProductsFoundText",
                defaultMessage: "No Products Available",
              })}
            </Typography>
          </Box>
        ) : (
          <>
            <ItemDragLayer />
            {isFetchingSortedProductsList && (
              <div className={classes.overlay}>
                <CircularProgress />
              </div>
            )}
            {isVerifyingProductCodes && renderPartNumberVerificationLoader()}
            <ProductList
              products={
                isFetchedBySearch
                  ? productsBySearch
                  : sequenceMode === 1
                    ? products
                    : editProducts
              }
            />
          </>
        )}
      </ViewContextProvider>
    </>
  );
};

export default React.memo(ProductListContainer);
