export enum QUICK_MOVE_DIRECTION {
  LEFT = 1,
  RIGHT,
}

export enum SEQUENCE_MODE_TYPE {
  VIEW = 1,
  EDIT,
}

export enum LEADING_COLOR_CHANGE_TYPE {
  INSERT = 1,
  UPDATE,
  DELETE,
  NOUPDATE,
}

export enum PRODUCT_ATTRIBUTE_CHANGE_TYPE {
  INSERT = 1,
  UPDATE,
  DELETE,
}

export enum COLOR_MANAGEMENT_VIEW_TYPE {
  ALL = 1,
  PUBLISHED,
  UNPUBLISHED,
}

export const MetricsList = [
  { label: "Price", value: "Price" },
  { label: "Inventory", value: "Inventory" },
  { label: "Item Sales", value: "Item Sales" },
  { label: "Items Sold", value: "Items Sold" },
  { label: "Conversion Rate", value: "Conversion Rate" },
  { label: "Items Abandoned", value: "Items Abandoned" },
  { label: "Abandonment Rate", value: "Abandonment Rate" },
  { label: "Product Views", value: "Product Views" },
  { label: "Stock Value", value: "Stock Value" },
];

export const PAGINATION_CHUNK_SIZE = 30;

export enum FACET_ACTION_TYPE {
  DELETE = 0,
  BLOCK,
}

export const CATALOG_NAVIGATION_TABS = {
  PRODUCTS: "products",
  SUB_CATEGORIES: "subcategories",
  LEAF_CATEGORIES: "leafcategories",
  FACETS: "facets",
};

export const REPORT_TYPE_NAVIGATION_TABS = {
  PRODUCT: "product",
  CATEGORY: "category",
};

export const BUSINESS_RULES_NAVIGATION_TABS = {
  SORTING_RULES: "sorting-rules",
  AUTOMATION_RULES: "automation-rules",
};

export const FACET_BUCKET_MODES = {
  VALUES: "VALUES",
  THRESHOLDS: "THRESHOLDS",
  PERIODS: "PERIODS",
  NONE: "NONE",
};

export const OVERLAY_TABS = {
  PRODUCTS: "products",
  ANALYTICS_OVERLAY: "analyticsOverlay",
  RATINGS: "ratings",
  CATEGORIES_OVERLAY: "categoriesOverlay",
  INVENTORY_OVERLAY: "inventoryOverlay",
  ATTRIBUTES_OVERLAY: "attributesOverlay",
};

export const PRODUCT_TILE_HEIGHTS = new Map([
  [2, 448],
  [3, 452],
  [4, 356],
  [5, 356],
  [6, 314],
]);

export const PRODUCT_TILE_HEIGHTS_SKELETON = new Map([
  [2, 442],
  [3, 442],
  [4, 342],
  [5, 342],
  [6, 302],
]);

export const PRODUCT_TILE_WIDTH_SKELETON = new Map([
  [2, 49],
  [3, 32],
  [4, 24],
  [5, 19],
  [6, 15.5],
]);

export const CURRENCY_MAP = {
  USD: "$",
  EUR: "€",
  GBP: "£",
  CHF: "CHF",
  JPY: "¥",
  CNY: "¥",
  KRW: "₩",
  CAD: "$",
  AUD: "$",
  NZD: "$",
  SGD: "$",
  BRL: "R$",
  RUB: "руб",
  RON: "lei",
  PLN: "zł",
};

export const PRODUCT_IMAGE_TILE_HEIGHTS = new Map([
  [2, 200],
  [3, 200],
  [4, 150],
  [5, 150],
  [6, 100],
]);

export const scoringFormulaTypes = [
  {
    label: "Take the highest of all the points",
    value: "Take the highest of all the points",
  },
  {
    label: "Take the lowest of all the points",
    value: "Take the lowest of all the points",
  },
  {
    label: "Take the average of all the points",
    value: "Take the average of all the points",
  },
  {
    label: "Take the sum of all the points",
    value: "Take the sum of all the points",
  },
];

export const periodTypes = [
  { label: "Last 7 days", value: "last_7_days" },
  { label: "Last 14 days", value: "last_14_days" },
  {
    label: "Last 30 days",
    value: "last_30_days",
  },
  {
    label: "Last 90 days",
    value: "last_90_days",
  },
  {
    label: "Last 180 days",
    value: "last_180_days",
  },
];
export const sortByOrder = [
  { label: "Asc", value: "ASC" },
  { label: "Desc", value: "DESC" },
];
export const repeats = [
  { label: "Daily", value: "Daily" },
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
];

export const repeatDays = [
  {
    label: "Monday",
    value: "MON",
  },
  {
    label: "Tuesday",
    value: "TUE",
  },
  {
    label: "Wednesday",
    value: "WED",
  },
  {
    label: "Thursday",
    value: "THU",
  },
  {
    label: "Friday",
    value: "FRI",
  },
  {
    label: "Saturday",
    value: "SAT",
  },
];

export const retries = [
  {
    label: 0,
    value: 0,
  },
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
];

export const IAutomationRepeats = {
  WEEKLY: "Weekly",
  DAILY: "Daily",
  MONTHLY: "Monthly",
};

export const IAutomationEndDuration = {
  ON: "On",
  NEVER: "Never",
};

export const DEFAULT_SORT_PERIOD_VALUE = "last_7_days";

export const VARIATION_GROUP = "Variation Group";

export const analyticMetrics = [
  "Item Sales",
  "Items Sold",
  "Conversion Rate",
  "Items Abandoned",
  "Abandonment Rate",
  "Product Views",
];

export const calculateIconSize = (column: number): number => {
  switch (column) {
    case 2:
      return 2.5;
    case 3:
      return 2.188;
    case 4:
      return 1.875;
    case 5:
      return 1.563;
    default:
      return 1.25;
  }
};
