import { useState, useEffect } from "react";
import { Typography, CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import {
  selectProductFetched,
  selectProductVariants,
  selectIsVariantFetched,
} from "../../store/product/ProductSelectors";
import AppState from "../../store/AppState";
import {
  FetchProductsVariants,
  ResetProductVariantsFetchedState,
} from "../../store/product/ProductActions";
import {
  selectIsModalOpen,
  selectModalVariable,
} from "../../store/modal/ModalSelectors";
import { CloseModal, setModalState } from "../../store/modal/ModalActions";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import {
  selectCurrentStoreId,
  selectCurrentLocale,
} from "../../store/store-list/StoreListSelectors";
import ItemManagementTable from "./ItemManagementTable";
import { getProductName } from "../../utils/ProductUtil";
import CustomDialog from "../common/DialogContainer";
import ButtonComponent from "../../components/common/ButtonComponent";
import { styled } from "@mui/material/styles";
export const itemManagementModalId = "ItemManagementModal";

export const classes = {
  dialogChildClass: "dialogChildClass",
  divActionBtn: "divActionBtn",
  dialogBlurClass: "dialogBlurClass",
  divTable: "divTable",
};

export const StyledDiv = styled("div")((theme) => ({
  [`&.${classes.dialogChildClass}`]: {
    position: "absolute",
    top: "50%",
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  [`&.${classes.divActionBtn}`]: {
    width: "100%",
    textAlign: "end",
    margin: "0px 35px 20px 20px",
  },
  [`&.${classes.divTable}`]: {
    minHeight: "60vh",
    minWidth: "47vw",
    padding: "24px 24px 0px",
  },
}));

export default function ItemManagementModal() {
  const intl = useIntl();
  const storeId = useSelector(selectCurrentStoreId) ?? "";
  const localeId = useSelector(selectCurrentLocale) || "";
  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<{
    [key: string]: { [sku: string]: boolean };
  }>({ Published: {} });

  const productId = useSelector((state: AppState) =>
    selectModalVariable(state, itemManagementModalId, "productId"),
  );

  const open = useSelector((state: AppState) =>
    selectIsModalOpen(state, itemManagementModalId),
  );

  const fetchedProduct = useSelector((state: AppState) =>
    selectProductFetched(state, productId),
  );

  const isVariantsFetched = useSelector((state: AppState) =>
    selectIsVariantFetched(state),
  );

  useEffect(() => {
    if (open) {
      const payload = {
        productId: productId,
        storeId: storeId,
        localeCode: localeId,
      };
      dispatch(FetchProductsVariants(payload));
    } else {
      dispatch(ResetProductVariantsFetchedState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const itemsData = useSelector(selectProductVariants);
  const handleClose = () => {
    dispatch(setModalState(itemManagementModalId, false));
    dispatch(CloseModal({ productId: productId }));
    setSelected({ Published: {} });
  };

  const itemManagementModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "itemManagementModal.modalTitle",
          defaultMessage: "Inventory Mgmt",
        })}
        {fetchedProduct
          ? ` - ${getProductName(fetchedProduct, localeId, defaultLocaleId)}`
          : ""}
      </Typography>
    );
  };

  const itemManagementModalContent = () => {
    return (
      <StyledDiv className={classes.divTable}>
        {!isVariantsFetched ? (
          <StyledDiv className={classes.dialogChildClass}>
            <CircularProgress color="secondary" />
          </StyledDiv>
        ) : (
          <ItemManagementTable
            selected={selected}
            setSelected={setSelected}
            productId={productId}
            items={itemsData.data.map((prod) => {
              const attributeCodes = {};
              prod?.variationOptions?.forEach((option) => {
                attributeCodes[option?.attributeLabel] = option?.valueLabel;
              });
              return {
                SKU: prod.code,
                ...attributeCodes,
                Inventory: prod.stock,
                Published: prod.isPublished,
              };
            })}
          />
        )}
      </StyledDiv>
    );
  };

  const itemManagementModalActions = () => {
    return (
      <StyledDiv className={classes.divActionBtn}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleClose}
          disabled={!isVariantsFetched}
        >
          {intl.formatMessage({
            id: "itemManagementModal.closeActionLabel",
            defaultMessage: "Close",
          })}
        </ButtonComponent>
      </StyledDiv>
    );
  };

  return (
    <CustomDialog
      title={itemManagementModalTitle()}
      maxWidth={"lg"}
      open={open}
      onClose={handleClose}
      actions={itemManagementModalActions()}
    >
      {itemManagementModalContent()}
    </CustomDialog>
  );
}
