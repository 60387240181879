import { takeEvery, put, call } from "redux-saga/effects";
import { callApi } from "../../utils/SagaUtils";
import {
  LoadStoreListAction,
  LOAD_STORE_LIST,
  FETCH_STORE_LIST,
} from "./StoreListTypes";
import { getData } from "../../services/ApiService";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";

export function* watchLoadStoreList() {
  yield takeEvery(LOAD_STORE_LIST, loadStoreList);
}

function* loadStoreList(action: LoadStoreListAction) {
  const actionType = FETCH_STORE_LIST;
  const constName = Object.keys({ FETCH_STORE_LIST })[0].toString();
  const headersObj = {
    "x-locale-code": "default",
    "x-currency-code": "USD",
    "x-store-id": "my-store",
    "x-catalog-id": "my-catalog",
  };
  try {
    const endpoint = acquireEndpoint(constName);
    yield call(callApi, actionType, getData, null, endpoint, headersObj);
  } catch (e: any) {
    console.error(e);
    yield put({ type: FETCH_STORE_LIST.FAILURE, message: e.message });
  }
}
