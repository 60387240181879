import { Box, Button, styled, Theme } from "@mui/material";

export const styleClasses = {
  overlay: "overlay",
  productAlertsScheduleContainer: "productAlertsScheduleContainer",
  buttonContainer: "buttonContainer",
  message: "message",
  productToggle: "productToggle",
  toggleBox: "toggleBox",
  toggleOn: "toggleOn",
  toggleOff: "toggleOff",
  box: "box",
};

export const StyledDiv = styled("div")(({ theme }) => ({
  [`&.${styleClasses.overlay}`]: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 99999,
    cursor: "pointer",
  },
  [`&.${styleClasses.productAlertsScheduleContainer}`]: {
    display: "flex",
    alignItems: "center", // Vertically center the SelectWrapper components
    gap: "20px", // Add equal spacing between the SelectWrapper components
  },
  [`&.${styleClasses.buttonContainer}`]: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0px",
  },
  [`&.${styleClasses.message}`]: {
    marginTop: "5px",
  },
}));

export const StyledToggleDiv = styled("div")(({ theme }) => ({
  [`&.${styleClasses.productToggle}`]: {
    display: "flex",
    alignItems: "flex-end",
    gap: 50,
  },
}));

export const StyledToggleBox = styled(Box)(({ theme }) => ({
  [`&.${styleClasses.toggleBox}`]: {
    borderRadius: "20px",
    position: "relative",
    background: theme.palette.neutral.brightGray,
    boxShadow: "1px 1px 2px rgb(165, 165, 165) inset",
    width: "inherit",
    display: "flex",
    "& .MuiButton-root": {
      borderRadius: "20px",
      minWidth: "50px",
      height: "40px",
      fontWeight: "bold",
      transition: "all 0.2s 0.1s ease",
      padding: 0,
    },
    "& .MuiButton-root:hover": {
      opacity: 0.8,
    },
  },
}));

export const StyledToggleOnButton = styled(Button)<{ toggleType: string }>(
  ({ toggleType, theme }) => ({
    [`&.${styleClasses.toggleOn}`]: {
      color:
        toggleType === "on"
          ? theme.palette.neutral.brightGray
          : theme.palette.neutral.secondaryDark,
    },
  }),
);

export const StyledToggleOffButton = styled(Button)<{ toggleType: string }>(
  ({ toggleType, theme }) => ({
    [`&.${styleClasses.toggleOff}`]: {
      color:
        toggleType === "off"
          ? theme.palette.neutral.brightGray
          : theme.palette.neutral.secondaryDark,
    },
  }),
);

export const StyledBox = styled(Box)<{ toggleType: string }>(
  ({ toggleType, theme }) => ({
    [`&.${styleClasses.box}`]: {
      width: 50,
      height: 42,
      borderRadius: 20,
      background: theme.palette.neutral.blue,
      position: "absolute",
      boxShadow: "1px 0 2px " + theme.palette.neutral.boxShadow,
      transition: "all 0.5s ease",
      transform: `translateX(${toggleType === "on" ? 0 : "50px"})`,
    },
  }),
);
export const useStyles = (theme: Theme) => {
  return {
    productAlertsModalDescription: {
      padding: "10px 0px 10px 0px",
      fontSize: "18px",
      fontWeight: 400,
      fontFamily: "Helvetica",
      color: theme.palette.text.primary,
    },
    productAlertToggleBox: {
      display: "flex",
      height: 40,
      width: 100,
    },
    divHeight: {
      height: 40,
    },
  };
};
