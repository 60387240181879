import React from "react";
import { styled, ToggleButtonGroup } from "@mui/material";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { useIntl } from "react-intl";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  }),
);

interface ToggleProps {
  toggleId: string;
  classes?: ClassNameMap;
  handleSequenceModeChange?: (
    event: React.MouseEvent<HTMLElement>,
    newMode: string | null,
  ) => void;
  mode?: string | null;
  isProductListsFetched?: boolean;
  isProductListFetching?: boolean;
  data?: { [key: string]: string };
  children?: JSX.Element;
}

const Toggle: React.FC<ToggleProps> = (props) => {
  const {
    toggleId,
    classes,
    handleSequenceModeChange,
    mode,
    isProductListsFetched,
    isProductListFetching,
    data,
    children,
  } = props;
  const intl = useIntl();
  const toggleclass = useStyles();

  switch (toggleId) {
    case "ProductListSwitch":
      if (classes && handleSequenceModeChange && mode) {
        const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
          [`&.${classes.root}`]: {
            padding: "7px",
            lineHeight: "normal",
            display: "none",
          },
        }));
        return (
          <ToggleButtonGroup
            exclusive
            value={mode}
            onChange={handleSequenceModeChange}
            aria-label="mode"
          >
            <StyledToggleButton
              value="View"
              aria-label="view"
              disabled={!isProductListsFetched || isProductListFetching}
              classes={{ root: classes.root }}
            >
              {intl.formatMessage({
                id: "productListSwitch.viewLabel",
                defaultMessage: "View",
              })}
              {children}
            </StyledToggleButton>
            <StyledToggleButton
              value="Edit"
              aria-label="edit"
              disabled={!isProductListsFetched || isProductListFetching}
              classes={{ root: classes.root }}
            >
              {intl.formatMessage({
                id: "productListSwitch.editLabel",
                defaultMessage: "Edit",
              })}
            </StyledToggleButton>
          </ToggleButtonGroup>
        );
      }
      break;
    default:
      const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
        [`&.${toggleclass.root}`]: {
          padding: "7px",
          lineHeight: "normal",
          display: "none",
        },
      }));
      if (data)
        return (
          <ToggleButtonGroup>
            {Object.values(data).map((e) => {
              return (
                <StyledToggleButton
                  value={e}
                  aria-label={e}
                  classes={{ root: toggleclass.root }}
                />
              );
            })}
          </ToggleButtonGroup>
        );
      if (children) return <ToggleButtonGroup>{children}</ToggleButtonGroup>;
  }
  return <></>;
};

export default Toggle;
