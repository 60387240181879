import React, { useEffect } from "react";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material";
import { useIntl } from "react-intl";
import { keyframes } from "@mui/system";
import {
  selectProductListSequenceMode,
  selectIsProductListsFetched,
  shouldUpdateEditSequenceList,
  selectWorkingProductSequence,
  selectIsProductListFetching,
} from "../store/product-list/ProductListSelectors";
import { OVERLAY_TABS, SEQUENCE_MODE_TYPE } from "../utils/Constants";
import {
  loadProductIdsFromCategory,
  loadProductsFromCategory,
  updateProductListSequenceMode,
} from "../store/product-list/ProductListActions";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
  selectStoreListIds,
} from "../store/store-list/StoreListSelectors";
import { selectCurrentCategory } from "../store/category/CategorySelectors";
import { selectCurrentCatalogId } from "../store/catalog/CatalogSelectors";
import { selectOverlay } from "../store/overlay/OverlaySelectors";
import { getProductsSocialAnalytics } from "../store/product-social-analytics/SocialAnalyticsActions";
import CustomDialog from "./common/DialogContainer";
import ButtonComponent from "../components/common/ButtonComponent";
import Toggle from "./common/Toggle";
import Icons from "./common/Icons";
import { makeStyles } from "hooks";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const styleClasses = {
  refreshContainer: "refreshContainer",
  root: "root",
  actiondiv: "actiondiv",
};

const StyledDiv = styled("div")(({ theme }) => ({
  [`&.${styleClasses.refreshContainer}`]: {
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
    color: "#709bb1",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main + "!important",
      "--icon-stroke-color":
        theme.palette.primaryHoverColor?.main + "!important", // Define the hover stroke color
    },
  },
  [`&.${styleClasses.actiondiv}`]: {
    width: "100%",
    textAlign: "end",
    margin: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "20px",
    height: "20px",
    animation: `${spin} 1s infinite ease`,
    stroke:
      "var(--icon-stroke-color, " + theme.palette.neutral.blue + ") !important", // Default stroke color
  },
  disabledIcon: {
    stroke: theme.palette.action.disabled + "!important", // Disabled stroke color
  },
  divMessage: {
    marginTop: "5px",
  },
}));

export default function ProductListSwitch() {
  const intl = useIntl();
  const classes = useStyles();
  const [mode, setMode] = React.useState<string | null>(() => "View");
  const dispatch = useDispatch();
  const sequenceMode = useSelector(selectProductListSequenceMode);
  const isProductListsFetched = useSelector(selectIsProductListsFetched);
  const isProductListFetching = useSelector(selectIsProductListFetching);

  const productIds = useSelector(selectWorkingProductSequence);
  const storeId = useSelector(selectCurrentStoreId);
  const categoryId = useSelector(selectCurrentCategory);
  const catalogId = useSelector(selectCurrentCatalogId);
  const localeCode = useSelector(selectCurrentLocale);
  const storeListIds = useSelector(selectStoreListIds);
  const overlay = useSelector(selectOverlay);
  const selected = sequenceMode === SEQUENCE_MODE_TYPE.EDIT;
  const isChangeMadeToTheCurrentProductList = useSelector(
    shouldUpdateEditSequenceList,
  );

  useEffect(() => {
    if (selected) {
      setMode("Edit");
    } else {
      setMode("View");
    }
  }, [selected]);

  const handleSequenceModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: string | null,
  ) => {
    if (newMode && newMode !== mode) {
      if (newMode === "Edit") {
        dispatch(updateProductListSequenceMode(SEQUENCE_MODE_TYPE.EDIT));
      } else if (newMode === "View" && isChangeMadeToTheCurrentProductList) {
        handleClickOpen();
      }
      setMode(newMode);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (selected) {
      setMode("Edit");
    }
    setOpen(false);
  };

  const handleClickSubmit = () => {
    dispatch(updateProductListSequenceMode(SEQUENCE_MODE_TYPE.VIEW));
    setOpen(false);
  };

  const refreshAllProducts = () => {
    if (storeId === null) {
      console.error("refreshAllProducts called with null storeId");
      return;
    }
    if (overlay === OVERLAY_TABS.RATINGS) {
      const payload = {
        productCodes: productIds,
        isRefreshingAllProducts: true,
      };
      dispatch(getProductsSocialAnalytics(payload));
    } else {
      let payload: Parameters<typeof loadProductIdsFromCategory>[0] = {
        categoryId,
        catalogId,
        localeCode,
        storeId,
        productIds,
        storeListIds,
        isRefreshingAllProducts: true,
      };

      dispatch(loadProductsFromCategory(payload));
    }
  };

  const productListSwitchContent = () => {
    return (
      <DialogContentText id="alert-dialog-description">
        <div className={classes.divMessage}>
          {intl.formatMessage({
            id: "productListSwitch.discardChangesText",
            defaultMessage:
              "Are you sure to discard all your changes to product list?",
          })}
        </div>
      </DialogContentText>
    );
  };

  const dialogActions = () => {
    return (
      <StyledDiv className={styleClasses.actiondiv}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "productListSwitch.cancelLabel",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleClickSubmit}
          autoFocus
        >
          {intl.formatMessage({
            id: "productListSwitch.confirmLabel",
            defaultMessage: "Confirm",
          })}
        </ButtonComponent>
      </StyledDiv>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Toggle
        toggleId="ProductListSwitch"
        classes={styleClasses}
        handleSequenceModeChange={handleSequenceModeChange}
        mode={mode}
        isProductListsFetched={isProductListsFetched}
        isProductListFetching={isProductListFetching}
      >
        <CustomDialog
          open={open && isChangeMadeToTheCurrentProductList}
          onClose={handleClose}
          title={<></>}
          actions={dialogActions()}
          maxWidth={"sm"}
        >
          {productListSwitchContent()}
        </CustomDialog>
      </Toggle>
      <StyledDiv
        onClick={() => refreshAllProducts()}
        className={styleClasses.refreshContainer}
      >
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          startIcon={
            <Icons
              iconId="RefreshIcon"
              type="bold"
              classes={`${classes.icon} ${!isProductListsFetched || isProductListFetching ? classes.disabledIcon : ""}`} // Conditionally apply the disabled color
            />
          }
          disabled={
            (!isProductListsFetched || isProductListFetching) &&
            productIds.length === 0
          }
        >
          REFRESH ALL
        </ButtonComponent>
      </StyledDiv>
    </div>
  );
}
