import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Skeleton } from "@mui/material";

import {
  LoadCatalogList,
  setProgressing,
} from "../../store/catalog/CatalogActions";
import {
  closeAllCategorySubscriptionsAndResetState,
  setCategoryExpandState,
} from "../../store/category/CategoryActions";
import {
  LoadStoreList,
  setCurrentStore,
  setCurrentLocale,
} from "../../store/store-list/StoreListActions";
import {
  selectStoreListIds,
  selectCurrentStoreId,
  selectAllStoreInfo,
  selectStoresLoadedState,
} from "../../store/store-list/StoreListSelectors";
import { resetProductList } from "../../store/product-list/ProductListActions";
import { useQuery } from "../../hooks/useQueryParams";
import { selectCurrentCatalogId } from "../../store/catalog/CatalogSelectors";
import { resetProducts } from "../../store/product/ProductActions";
import { selectCallerAccountId } from "../../store/app-config/AppConfigSelectors";
import DropdownMenu from "../common/DropdownMenu";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1, 1),
  },
  skeletonLabel: {
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
  },
}));

const StoreSelection: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const storeIdQuery = query.get("storeId") ?? "";
  const location = useLocation();
  const url = location.pathname;
  const storeListIds = useSelector(selectStoreListIds);
  const currentStoreId = useSelector(selectCurrentStoreId) ?? "";
  const currentCatalogId = useSelector(selectCurrentCatalogId);
  const isStoresLoaded = useSelector(selectStoresLoadedState);
  const userAccountId = useSelector(selectCallerAccountId);

  const allStoreInfo = useSelector(selectAllStoreInfo);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(LoadStoreList());
  }, [dispatch]);

  const handleStoreChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const changeEvent = event as React.ChangeEvent<{ value: string }>;

    dispatch(setProgressing());
    dispatch(setCurrentLocale(""));
    dispatch(resetProductList());
    dispatch(resetProducts());
    dispatch(setCurrentStore(changeEvent.target.value));
    dispatch(closeAllCategorySubscriptionsAndResetState(currentCatalogId));
    dispatch(setCategoryExpandState());
    if (changeEvent.target.value !== "") {
      dispatch(LoadCatalogList(changeEvent.target.value));
    }
    const storeInfo = allStoreInfo[changeEvent.target.value];
    if (storeInfo) {
      history.replace(
        `${url}?accountId=${userAccountId}&storeId=${changeEvent.target.value}&localeCode=${storeInfo.storeDefaults.localeCode}&catalogId=${storeInfo.storeDefaults.catalogId}`,
      );
    } else {
      history.replace(
        `${url}?accountId=${userAccountId}&storeId=${changeEvent.target.value}`,
      );
    }
  };

  useEffect(() => {
    if (storeListIds.find((e) => e.storeId === storeIdQuery)) {
      if (storeIdQuery !== currentStoreId) {
        dispatch(setCurrentStore(storeIdQuery));
        dispatch(LoadCatalogList(storeIdQuery));
      }
    } else {
      if (currentStoreId !== "") {
        dispatch(setCurrentStore(""));
      }
    }
  }, [currentStoreId, dispatch, storeIdQuery, storeListIds]);

  useEffect(() => {
    if (
      storeListIds.length > 0 &&
      !storeListIds.find((e) => e.storeId === storeIdQuery)
    ) {
      history.replace(`${url}?accountId=${userAccountId}`);
    }
  }, [history, storeIdQuery, storeListIds, url, userAccountId]);

  return (
    <>
      {!isStoresLoaded ? (
        <div className={classes.skeletonLabel}>
          <Skeleton
            variant="rectangular"
            width={"90%"}
            height={30}
            animation="wave"
          />
        </div>
      ) : isStoresLoaded && !storeListIds.length ? (
        <></>
      ) : (
        <DropdownMenu
          menuId="StoreSelection"
          classes={classes}
          handleStoreChange={handleStoreChange}
        />
      )}
    </>
  );
};

export default React.memo(StoreSelection);
